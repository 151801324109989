import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { UtilService } from './api/util.service';
import { AlertDialogService } from './material/alert-dialog.service';
import { CurrentPosition } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private isPermissionsApiSupported = !!navigator.permissions;
  private permissionState$ = new ReplaySubject<PermissionState>(1);

  currentPosition$: Observable<CurrentPosition>;
  isPermissionDenied$: Observable<boolean>;

  constructor(
    private utilService: UtilService,
    private alertDialogService: AlertDialogService
  ) {
    this.permissionState$ = this.getPermissionState();
    this.isPermissionDenied$ = this.getIsPermissionDenied();
    this.currentPosition$ = this.getCurrentPosition();
  }

  findMe(): Observable<CurrentPosition> {
    return this.permissionState$
      .pipe(
        switchMap(permissionState => {
          if (permissionState === 'granted') {
            return this.currentPosition$;
          } else {
            return this.getPositionFromNavigator();
          }
        }),
        catchError((error: GeolocationPositionError) => {
          return this.showError(error)
            .pipe(
              switchMap(() => this.currentPosition$)
            );
        }),
        take(1)
      );
  }

  getPositionFromNavigator(): Observable<CurrentPosition> {
    return new Observable<CurrentPosition>(observer => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          observer.next(new CurrentPosition(position.coords, 'navigator'));
          observer.complete();
        },
        (error: GeolocationPositionError) => observer.error(error),
        { timeout: 2000 }
      );
    })
      .pipe(
        tap(() => this.grantPermissionIfApiNotSupported())
      );
  }

  showError(error: GeolocationPositionError): Observable<any> {
    // TODO block and not now throws same error
    if (error.code === error.PERMISSION_DENIED) {
      return this.alertDialogService.showError('Geolocation is denied');
    } else {
      return this.alertDialogService.showError('Geolocation is currently not available');
    }
  }

  private getPermissionState(): ReplaySubject<PermissionState> {
    const permissionState$ = new ReplaySubject<PermissionState>(1);
    if (this.isPermissionsApiSupported) {
      navigator.permissions.query({ name: 'geolocation' }).then(
        (permissionStatus: PermissionStatus) => {
          permissionState$.next(permissionStatus.state);
          permissionStatus.onchange = () => permissionState$.next(permissionStatus.state);
        }
      );
    } else {
      // TODO save in localStorage
      permissionState$.next('prompt');
    }
    return permissionState$;
  }

  private getIsPermissionDenied(): Observable<boolean> {
    return this.permissionState$
      .pipe(
        map(permissionState => permissionState === 'denied'),
        distinctUntilChanged()
      );
  }

  private getCurrentPosition(): Observable<CurrentPosition> {
    return this.permissionState$
      .pipe(
        switchMap(permissionState => {
          if (permissionState === 'granted') {
            return this.watchPositionFromNavigator();
          } else {
            return throwError(null);
          }
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError(() => this.utilService.getPositionFromIpLookup()),
        catchError(() => of(CurrentPosition.defaultCurrentPosition))
      );
  }

  private watchPositionFromNavigator(): Observable<CurrentPosition> {
    return new Observable<CurrentPosition>(observer => {
      const watchId = navigator.geolocation.watchPosition(
        (position: GeolocationPosition) => observer.next(new CurrentPosition(position.coords, 'navigator')),
        (error: GeolocationPositionError) => observer.error(error)
      );
      return () => navigator.geolocation.clearWatch(watchId);
    })
      .pipe(
        tap(() => this.grantPermissionIfApiNotSupported())
      );
  }

  private grantPermissionIfApiNotSupported(): void {
    if (!this.isPermissionsApiSupported) {
      this.permissionState$
        .pipe(
          take(1),
          filter(permissionState => permissionState !== 'granted')
        )
        .subscribe(() => this.permissionState$.next('granted'));
    }
  }
}
