import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TourAttraction } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TourAttractionService {
  private readonly requestFields = ['address_component', 'formatted_address', 'geometry.location', 'name', 'place_id', 'vicinity'];
  private geocoder: google.maps.Geocoder;
  private placesService: google.maps.places.PlacesService;

  constructor(
    private ngZone: NgZone
  ) {
    this.geocoder = new google.maps.Geocoder();

    const fakeMapContainer = document.createElement('div');
    this.placesService = new google.maps.places.PlacesService(fakeMapContainer);
  }

  getTourAttractionByPlaceId(placeId: string): Observable<TourAttraction> {
    return this.getPlaceById(placeId)
      .pipe(
        map((place: google.maps.places.PlaceResult) => this.getTourAttractionByPlace(place))
      );
  }

  getTourAttractionByPlace(place: google.maps.places.PlaceResult): TourAttraction {
    const country = this.getNameByType(place.address_components, 'country');
    const locality = this.getNameByType(place.address_components, 'locality');
    const postalTown = this.getNameByType(place.address_components, 'postal_town');
    return {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      placeId: place.place_id,
      placeName: place.name,
      country,
      town: locality ?? postalTown, // for example, locality is missing in UK
      address: place.vicinity ?? place.formatted_address // formatted address looks more like postal address, vicinity is more informal
    };
  }

  getTourAttractionByLatLng(latLng: google.maps.LatLng, latLngBounds?: google.maps.LatLngBounds): Observable<TourAttraction> {
    return this.getPlaceIdByLatLng(latLng, latLngBounds)
      .pipe(
        switchMap(placeId => this.getTourAttractionByPlaceId(placeId))
      );
  }

  private getPlaceById(placeId: string): Observable<google.maps.places.PlaceResult> {
    return new Observable<google.maps.places.PlaceResult>(observer => {
      const request: google.maps.places.PlaceDetailsRequest = { placeId, fields: this.requestFields };
      this.placesService.getDetails(request, (data, status) => {
        this.ngZone.run(() => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            observer.next(data);
            observer.complete();
          } else {
            observer.error();
          }
        });
      });
    });
  }

  private getPlaceIdByLatLng(latLng: google.maps.LatLng, latLngBounds?: google.maps.LatLngBounds): Observable<string> {
    return new Observable<string>(observer => {
      const request: google.maps.GeocoderRequest = { location: latLng, bounds: latLngBounds };
      this.geocoder.geocode(request, (data, status) => {
        this.ngZone.run(() => {
          if (status === google.maps.GeocoderStatus.OK && data.length) {
            const pointOfInterestResult = data.find(result => result.types.includes('point_of_interest'));
            const geometricCenterResult = data.find(place => place.geometry.location_type === 'GEOMETRIC_CENTER');
            const geocoderResult = pointOfInterestResult ?? geometricCenterResult ?? data[0];
            observer.next(geocoderResult.place_id);
            observer.complete();
          } else {
            observer.error();
          }
        });
      });
    });
  }

  private getNameByType(addressComponents: google.maps.GeocoderAddressComponent[], type: string): string {
    return addressComponents.find(component => component.types.includes(type))?.long_name;
  }
}
