import { Directive, HostListener, HostBinding, OnInit, OnDestroy, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, tap, retry, map } from 'rxjs/operators';

import { MapService, GeolocationService } from '@core/services';
import { CurrentPosition } from '@core/models';

@Directive({
  selector: '[appMapGeolocation]'
})
export class MapGeolocationDirective implements OnInit, OnDestroy {
  private geolocationUpdated$ = new Subject();
  private ngUnsubscribe$ = new Subject();
  private isDisabled = !navigator.geolocation;

  @HostBinding('disabled') get disabledAttribute(): boolean { return this.isDisabled; }
  @HostBinding('class.mat-button-disabled') get disabledClass(): boolean { return this.isDisabled; }

  @HostListener('click') getGeolocation(): void {
    this.geolocationUpdated$.next();
  }

  constructor(
    private mapService: MapService,
    private geolocationService: GeolocationService,
    @SkipSelf() private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.geolocationService.isPermissionDenied$
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(isPermissionDenied => {
        this.isDisabled = isPermissionDenied;
        this.changeDetectorRef.detectChanges();
      });

    this.geolocationUpdated$
      .pipe(
        switchMap(() => this.geolocationService.getPositionFromNavigator()),
        map((currentPosition: CurrentPosition) => currentPosition.toLatLng()),
        tap({
          next: (latLng: google.maps.LatLng) => this.mapService.map.panTo(latLng),
          error: (error: GeolocationPositionError) => this.geolocationService.showError(error)
        }),
        // TODO three times Not now in a row
        // TODO check if 2 or 3
        retry(3),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        error: () => {
          this.isDisabled = true;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
