<app-map [latitude]="data.center.lat()" [longitude]="data.center.lng()" [zoom]="8"></app-map>

<div class="map-control-container">
  <button mat-icon-button mat-dialog-close aria-label="Close" color="blue-dark-on-white" class="map-control-button"
    appAnalytics="map-close">
    <app-svg-icon icon="map/close"></app-svg-icon>
  </button>

  <!-- TODO disable button if geolocation disabled -->
  <button mat-icon-button color="blue-dark-on-white" aria-label="Find me" class="map-control-button" (click)="findMe()"
    appAnalytics="map-find-me">
    <app-svg-icon icon="map/find-me"></app-svg-icon>
  </button>

  <button mat-icon-button aria-label="List" *ngIf="data.tours?.length"
    [color]="isMenuOpen ? 'white-on-blue-dark' : 'blue-dark-on-white'" class="map-control-button"
    [matMenuTriggerFor]="tourList" #menuButton="matMenuTrigger" (menuOpened)="isMenuOpen = menuButton.menuOpen"
    (menuClosed)="isMenuOpen = menuButton.menuOpen" appAnalytics="map-list">
    <app-svg-icon icon="map/list"></app-svg-icon>
  </button>
</div>

<mat-menu #tourList="matMenu" xPosition="before" yPosition="above">
  <ng-container *appLet="currentPosition$ | async as currentPosition">
    <ng-container *appLet="sortedTours$ | async as sortedTours">
      <a mat-menu-item color="blue" *ngFor="let tour of sortedTours" class="tour-link"
        [routerLink]="['/tours', tour.id]" appAnalytics="map-tour" [appAnalyticsKey]="tour.id">
        <img [src]="tour.coverPhotoId | photoSrc:'thumbnail' | async" alt="thumbnail" class="tour-thumbnail"
          [style.borderColor]="tourColors[tour.id]" />
        <div class="tour-info">
          <span class="tour-title">{{ tour.title.isEmpty ? tour.placeName : (tour | translateTitle | async) }}</span>
          <span class="tour-distance">{{ tour | distance:currentPosition }}</span>
        </div>
      </a>
    </ng-container>
  </ng-container>
</mat-menu>
