import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PointOfView } from '@core/interfaces';
import { Photo } from '@core/models';
import { StreetViewGeometryService } from './street-view-geometry.service';
import { StreetViewService } from './street-view.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlay: Overlay;

  constructor(
    private ngZone: NgZone,
    private streetViewService: StreetViewService,
    private streetViewGeometryService: StreetViewGeometryService
  ) { }

  addOverlay(photo: Photo, canvas: HTMLCanvasElement): void {
    this.streetViewService.streetView.setOptions({ clickToGo: false });

    this.ngZone.runOutsideAngular(() => this.overlay = this.initOverlay(photo, canvas));
  }

  removeOverlay(): void {
    this.streetViewService.streetView.setOptions({ clickToGo: true });

    if (this.overlay) {
      this.overlay.setMap(null);
      this.overlay = null;
    }
  }

  panToStreetView(photo: Photo): Observable<any> {
    const isSamePano = photo.pano === this.streetViewService.streetView.getPano();
    const streetViewPointOfView = this.streetViewGeometryService.getStreetViewPointOfView(photo, this.streetViewService.getContainerAspect());
    if (isSamePano) {
      return this.streetViewService.panToPointOfView(streetViewPointOfView);
    } else {
      this.streetViewService.streetView.setPano(photo.pano);
      return of(this.streetViewService.setPointOfView(streetViewPointOfView));
    }
  }

  toggleOverlay(): void {
    this.overlay.toggle();
  }

  private initOverlay(photo: Photo, canvas: HTMLCanvasElement): Overlay {
    const photoPointOfView = this.streetViewGeometryService.getOverlayPointOfView(photo);
    return new Overlay(this.streetViewService.streetView, canvas, photoPointOfView, photo.roll);
  }
}

class Overlay extends google.maps.OverlayView {
  private readonly OPACITY = 0.8;
  private container: HTMLDivElement;

  constructor(
    private streetView: google.maps.StreetViewPanorama,
    private canvas: HTMLCanvasElement,
    private hotspotPointOfView: PointOfView,
    private hotspotRoll: number
  ) {
    super();
    this.setMap(this.streetView);
  }

  onAdd(): void {
    const overlayLayer = this.getPanes().overlayLayer as HTMLElement;
    overlayLayer.style.height = '100%';
    overlayLayer.style.pointerEvents = 'none';

    this.container = document.createElement('div');
    this.container.style.height = '100%';
    this.container.style.display = 'flex';
    this.container.style.justifyContent = 'center';
    this.container.style.alignItems = 'center';

    this.canvas.style.opacity = '0';
    this.canvas.style.transition = 'opacity 1s ease-in 1s';
    setTimeout(() => {
      this.canvas.style.opacity = this.OPACITY.toString();
      this.canvas.style.transition = 'opacity 1s ease-out';
    });

    overlayLayer.appendChild(this.container);
    this.container.appendChild(this.canvas);
  }

  draw(): void {
    const containerWidth = this.container.offsetWidth;
    const containerHeight = this.container.offsetHeight;
    const canvasWidth = this.canvas.offsetWidth;

    const heading = this.streetView.getPov().heading;
    const pitch = this.streetView.getPov().pitch;
    const zoom = this.streetView.getZoom();

    const perspective = Math.pow(2, zoom - 2) * containerWidth;
    const scale = Math.pow(2, zoom - this.hotspotPointOfView.zoom) * containerWidth / canvasWidth;

    let transform = '';

    // center hotspot in screen
    transform += `translateX(${containerWidth / 2}px) `;
    transform += `translateY(${containerHeight / 2}px) `;
    transform += 'translateX(-50%) translateY(-50%) ';

    // set the perspective depth
    transform += `perspective(${perspective}px) `;
    transform += `translateZ(${perspective}px) `;

    // set the camera rotation
    transform += `rotateX(${pitch}deg) `;
    transform += `rotateY(${heading}deg) `;

    // set the hotspot position
    transform += `rotateY(${-this.hotspotPointOfView.heading}deg) `;
    transform += `rotateX(${-this.hotspotPointOfView.pitch}deg) `;
    transform += `rotateZ(${this.hotspotRoll}deg) `;
    transform += `scale(${scale}, ${scale}) `;

    // move back to sphere
    transform += `translateZ(${-perspective}px) `;

    this.container.style.transform = transform;
  }

  onRemove(): void {
    this.container.parentNode.removeChild(this.container);
    this.container = null;
  }

  toggle(): void {
    const currentOpacity = Number(this.canvas.style.opacity);
    this.canvas.style.opacity = (this.OPACITY - currentOpacity).toString();
  }
}
