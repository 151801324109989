import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

import { MapService } from './map.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  private readonly MARKER_OPACITY = 0.8;

  // TODO get svg path from marker.svg
  private readonly photoMarkerSvgPath = `M195.2 256.1c-50.5 72.4-57.8 133.6-57.8 133.6-1.1 5.1-5.6 5.1-6.7 0 0
    0-9.5-61.2-58.9-133.6-41-57.2-59.5-79.7-59.5-126.3C12.3 63.1 65.6 9.7 132.9 9.7c66.8 0 121.8 53.3 121.8 120.1 0 46.6-35.9 92.1-59.5
    126.3zm-14.6-124c0-25.8-20.2-46-46.6-46a45.77 45.77 0 0 0-45.5 46c0 25.8 20.8 47.7 45.5 47.7 26.4 0 46.6-21.9 46.6-47.7z`;

  // TODO get stroke color from marker.svg
  private readonly markerColors = [
    // set of constrast colors
    '#0098ff', '#1d47fc', '#fc5912', '#0f3296', '#f29e22', '#77ad53', '#d488ed', '#c9c936',
    '#96c1cc', '#ff4800', '#44a547', '#40ccb1', '#4867ed', '#824425', '#1697f7', '#be10ff'
  ];

  private readonly photoMarkerOptions: google.maps.MarkerOptions = {
    opacity: this.MARKER_OPACITY
  };

  private readonly photoMarkerIcon: google.maps.Symbol = {
    path: this.photoMarkerSvgPath,
    scale: 0.1,
    anchor: new google.maps.Point(130, 400),
    fillOpacity: 1,
    strokeWeight: 1.5
  };

  private readonly currentPositionMarkerOptions: google.maps.MarkerOptions = {
    icon: { url: 'assets/icons/map/marker-here.gif', scaledSize: new google.maps.Size(30, 30) },
    opacity: this.MARKER_OPACITY,
    zIndex: -1
  };

  private markers = new Set<google.maps.Marker>();

  constructor(
    private ngZone: NgZone,
    private mapService: MapService,
    private eventService: EventService
  ) { }

  addDraggableMarker(latLng: google.maps.LatLng): google.maps.Marker {
    return this.addMarker(latLng, { draggable: true });
  }

  addPhotoMarker(latLng: google.maps.LatLng, markerColor: string): google.maps.Marker {
    const strokeColor = this.darkenColor(markerColor);
    const markerIcon: google.maps.Symbol = { ...this.photoMarkerIcon, ...{ fillColor: markerColor, strokeColor } };
    const markerOptions: google.maps.MarkerOptions = { ...this.photoMarkerOptions, ...{ icon: markerIcon } };
    return this.addMarker(latLng, markerOptions);
  }

  addCurrentPositionMarker(latLng: google.maps.LatLng): google.maps.Marker {
    return this.addMarker(latLng, this.currentPositionMarkerOptions);
  }

  moveMarker(marker: google.maps.Marker, latLng: google.maps.LatLng): void {
    if (marker?.getDraggable()) {
      marker.setPosition(latLng);
    }
  }

  removeMarker(marker: google.maps.Marker): void {
    if (marker) {
      google.maps.event.clearInstanceListeners(marker);
      marker.setMap(null);

      this.markers.delete(marker);
      marker = null;
    }
  }

  removeAllMarkers(): void {
    this.markers.forEach(marker => this.removeMarker(marker));
  }

  fromMarkerEvent<T>(marker: google.maps.Marker, eventName: string): Observable<T> {
    return this.eventService.fromEvent<T>(marker, eventName);
  }

  getMarkerColor(index: number): string {
    return this.markerColors[index % this.markerColors.length];
  }

  private addMarker(latLng: google.maps.LatLng, options?: google.maps.MarkerOptions): google.maps.Marker {
    const markerOptions: google.maps.MarkerOptions = { map: this.mapService.map, position: latLng, ...options };

    let marker: google.maps.Marker;
    this.ngZone.runOutsideAngular(() => marker = new google.maps.Marker(markerOptions));

    this.markers.add(marker);
    return marker;
  }

  private darkenColor(hexColor: string, brightness = 0.8): string {
    const [r, g, b] = hexColor.match(/\w\w/g).map(c => parseInt(c, 16));
    return '#' + [r, g, b].map(c => Math.round(c * brightness).toString(16).padStart(2, '0')).join('');
  }
}
