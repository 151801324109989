import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService, JwtService } from '@core/services';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private jwtService: JwtService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn) {
      const reqClone = req.clone({
        setHeaders: { Authorization: `Bearer ${this.jwtService.jwt}` }
      });
      return next.handle(reqClone)
        .pipe(
          tap({
            error: error => {
              if ([401, 403].includes(error.status)) {
                this.authService.logout();
                this.router.navigate(['auth'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
              }
            }
          })
        );
    } else {
      return next.handle(req);
    }
  }
}
