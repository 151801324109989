import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Tour } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private initialTitle: string;

  constructor(
    private titleService: Title
  ) {
    this.initialTitle = this.titleService.getTitle();
  }

  setTitle(title: string): void {
    this.titleService.setTitle(`${title} | ${this.initialTitle}`);
  }

  setTitleByTour(tour: Tour): void {
    const tourTitle = tour.title.isEmpty ? tour.placeName : tour.title.text;
    this.setTitle(tourTitle);
  }

  resetTitle(): void {
    this.titleService.setTitle(this.initialTitle);
  }
}
