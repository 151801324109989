import { Pipe, PipeTransform } from '@angular/core';

import { AdminPhotoService } from '@core/services';
import { PhotoSize } from '@core/types';

@Pipe({
  name: 'adminPhotoSrc'
})
export class AdminPhotoSrcPipe implements PipeTransform {
  constructor(
    private adminPhotoService: AdminPhotoService
  ) { }

  transform(photoId: string, photoSize: PhotoSize): string {
    return photoId
      ? this.adminPhotoService.getPhotoFileUrl(photoId, photoSize)
      : 'assets/images/dummy.png';
  }
}
