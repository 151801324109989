import { Pipe, PipeTransform } from '@angular/core';

import { CurrentPosition, Tour } from '@core/models';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {
  transform(tour: Tour, currentPosition: CurrentPosition): string {
    if (!tour || !currentPosition) {
      return '';
    }

    if (currentPosition.source === 'default') {
      return '';
    }

    const meters = google.maps.geometry.spherical.computeDistanceBetween(tour.toLatLng(), currentPosition.toLatLng());
    if (meters >= 10000) {
      return `${Math.round(meters / 1000)} km`;
    } else if (meters >= 1000) {
      return `${Math.round(meters / 100) / 10} km`;
    } else {
      return `${Math.round(meters)} m`;
    }
  }
}
