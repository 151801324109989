import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Tour } from '@core/models';

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourCardComponent implements OnInit {
  @Input() tour: Tour;
  @Input() distance: string;

  dummyFilter: string;

  constructor() { }

  ngOnInit(): void {
    const constrast = Math.random() / 3 + 0.8;
    const brightness = Math.random() / 3 + 0.8;
    this.dummyFilter = `contrast(${constrast}) brightness(${brightness})`;
  }
}
