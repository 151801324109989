import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Caption, Photo } from '@core/models';
import { KeyOfType, PhotoSize } from '@core/types';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  constructor(
    private apiService: ApiService,
    private cacheService: CacheService
  ) { }

  getPhotos(): Observable<Photo[]> {
    if (this.cacheService.isPhotosCached) {
      return of(this.cacheService.getPhotos());
    } else {
      return this.apiService.getInstanceArray<Photo>('/photos', Photo)
        .pipe(
          tap(photos => this.cacheService.cachePhotos(photos))
        );
    }
  }

  getPhoto(photoId: string): Observable<Photo> {
    if (this.cacheService.isPhotosCached) {
      return of(this.cacheService.getPhoto(photoId));
    } else {
      return this.apiService.getInstance(`/photos/${photoId}`, Photo);
    }
  }

  getPhotoFileUrl(photoId: string, photoSize: PhotoSize): Observable<string> {
    return this.getPhoto(photoId)
      .pipe(
        map(photo => {
          const photoDateModified = +new Date(photo.dateModified);
          return this.apiService.buildUrl(`/photos/${photoId}/file/${photoSize}?t=${photoDateModified}`);
        })
      );
  }

  getCaptionTranslation(photoId: string, captionType: KeyOfType<Photo, Caption>, language: string): Observable<string> {
    return this.apiService.getString(`/photos/${photoId}/${captionType}/${language}`);
  }
}
