import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CurrentPosition } from '@core/models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private positionFromIpLookup$: Observable<CurrentPosition>;

  constructor(
    private apiService: ApiService
  ) {
    this.positionFromIpLookup$ = this.apiService.get<Pick<CurrentPosition, 'latitude' | 'longitude'>>('/util/ip')
      .pipe(
        // TODO cache response, if error
        shareReplay(1),
        map(coords => new CurrentPosition(coords, 'iplookup'))
      );
  }

  getPositionFromIpLookup(): Observable<CurrentPosition> {
    return this.positionFromIpLookup$;
  }
}
