import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';

import { AutocompleteService } from '@core/services';

@Component({
  selector: 'app-map-autocomplete',
  templateUrl: './map-autocomplete.component.html',
  styleUrls: ['./map-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapAutocompleteComponent implements AfterViewInit, OnDestroy {
  @Input() label = 'Search here';
  @ViewChild('inputEl') inputRef: ElementRef<HTMLInputElement>;

  private ngUnsubscribe$ = new Subject();

  constructor(
    private autocompleteService: AutocompleteService
  ) { }

  ngAfterViewInit(): void {
    this.autocompleteService.addAutocomplete(this.inputRef.nativeElement);
    this.autocompleteService.fromAutocompleteEvent('place_changed')
      .pipe(
        tap(() => this.autocompleteService.clearAutocomplete()),
        map(() => this.autocompleteService.getAutocompletePlace()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((place: google.maps.places.PlaceResult) => this.autocompleteService.place$.next(place));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.autocompleteService.removeAutocomplete();
  }

  clear(): void {
    this.autocompleteService.clearAutocomplete();
  }
}
