import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Photo, Tour } from '@core/models';
import { TranslateService } from '@core/services';

@Pipe({
  name: 'translateTitle'
})
export class TranslateTitlePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) { }

  transform(tourOrPhoto: Tour | Photo): Observable<string> {
    return this.translateService.translateTitle(tourOrPhoto);
  }
}
