import { Pipe, PipeTransform } from '@angular/core';

import { DateService } from '@core/services';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  constructor(
    private dateService: DateService
  ) { }

  transform([dateFrom, dateTo]: [Date | string, Date | string]): string {
    return this.dateService.getDateRangeText(dateFrom, dateTo);
  }
}
