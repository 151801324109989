import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';

import { Tour, Photo, Caption } from '@core/models';
import { TourService } from './api/tour.service';
import { PhotoService } from './api/photo.service';
import { KeyOfType } from '@core/types';
import { CaptionInfo } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private readonly ENGLISH_LANGUAGE = 'en';
  private shouldBeTranslated$ = new BehaviorSubject<boolean>(true);

  constructor(
    private tourService: TourService,
    private photoService: PhotoService
  ) { }

  translate(tourOrPhoto: Tour | Photo): Observable<Record<'title' | 'description', CaptionInfo>> {
    return combineLatest([
      this.getCaptionInfo(tourOrPhoto, 'title'),
      this.getCaptionInfo(tourOrPhoto, 'description')
    ])
      .pipe(
        map(([titleInfo, descriptionInfo]) => ({
          title: titleInfo,
          description: descriptionInfo
        }))
      );
  }

  translateTitle(tourOrPhoto: Tour | Photo): Observable<string> {
    return this.getCaptionInfo(tourOrPhoto, 'title')
      .pipe(
        map(captionInfo => captionInfo.currentText)
      );
  }

  showTranslations(): void {
    this.shouldBeTranslated$.next(true);
  }

  switchLanguage(): void {
    this.shouldBeTranslated$.next(!this.shouldBeTranslated$.value);
  }

  private getCaptionInfo(tourOrPhoto: Tour | Photo, captionType: KeyOfType<Tour | Photo, Caption>): Observable<CaptionInfo> {
    const caption = tourOrPhoto[captionType];
    const isOriginalPreferred = this.checkIsOriginalPreferred(caption);
    const captionInfoForOriginal: CaptionInfo = {
      currentText: caption.text,
      currentLanguage: caption.language,
      isOriginalPreferred
    };

    if (isOriginalPreferred) {
      return of(captionInfoForOriginal);
    } else {
      return this.shouldBeTranslated$
        .pipe(
          switchMap(shouldBeTranslated => {
            if (shouldBeTranslated) {
              const isSomeTranslationPreferred = this.checkIsSomeTranslationPreferred(caption);
              if (isSomeTranslationPreferred) {
                return of(this.getCaptionInfoForPreferredTranslation(caption));
              } else {
                return this.getCaptionInfoForNewTranslation(tourOrPhoto, captionType);
              }
            } else {
              return of(captionInfoForOriginal);
            }
          })
        );
    }
  }

  private getCaptionInfoForPreferredTranslation(caption: Caption): CaptionInfo {
    const preferredTranslationLanguage = this.preferredLanguages.find(language => language in caption.translations);
    const preferredTranslationText = caption.translations[preferredTranslationLanguage];
    return {
      currentText: preferredTranslationText,
      currentLanguage: preferredTranslationLanguage,
      isOriginalPreferred: false
    };
  }

  private getCaptionInfoForNewTranslation(
    tourOrPhoto: Tour | Photo, captionType: KeyOfType<Tour | Photo, Caption>
  ): Observable<CaptionInfo> {
    const caption = tourOrPhoto[captionType];
    const preferredLanguage = this.preferredLanguages[0];
    const service = tourOrPhoto instanceof Tour ? this.tourService : this.photoService;
    const englishOrOriginalLanguage = this.ENGLISH_LANGUAGE in caption.translations ? this.ENGLISH_LANGUAGE : caption.language;
    return service.getCaptionTranslation(tourOrPhoto.id, captionType, preferredLanguage)
      .pipe(
        catchError(() => of(null)),
        tap(translationText => caption.addTranslationIfNotEmpty(translationText, preferredLanguage)),
        map(() => preferredLanguage in caption.translations ? preferredLanguage : englishOrOriginalLanguage),
        startWith(englishOrOriginalLanguage),
        map(language => ({
          currentText: caption.translations[language] ?? caption.text,
          currentLanguage: language,
          isOriginalPreferred: false
        }))
      );
  }

  private checkIsOriginalPreferred(caption: Caption): boolean {
    return caption.isEmpty || this.preferredLanguages.includes(caption.language);
  }

  private checkIsSomeTranslationPreferred(caption: Caption): boolean {
    return this.preferredLanguages.some(language => language in caption.translations);
  }

  private get preferredLanguages(): string[] {
    const languages = (navigator.languages ?? [navigator.language]) as string[];
    const languageCodes = languages.map(language => language.substring(0, 2).toLowerCase());
    return [...new Set(languageCodes)];
  }
}
