import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Dto } from '@app/core/interfaces';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService
  ) { }

  buildUrl(url: string): string {
    return environment.apiUrl + url;
  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.buildUrl(url));
  }

  getInstance<T>(url: string, dtoClass: Dto<T>): Observable<T> {
    return this.get<T>(url)
      .pipe(map(dto => dtoClass.fromDto(dto)));
  }

  getInstanceArray<T>(url: string, dtoClass: Dto<T>): Observable<T[]> {
    return this.get<T[]>(url)
      .pipe(map(dtoArray => dtoArray.map(dto => dtoClass.fromDto(dto))));
  }

  getString(url: string): Observable<string> {
    return this.http.get(this.buildUrl(url), { responseType: 'text' });
  }

  post<T>(url: string, payload?: object, options?: { withCredentials?: boolean }): Observable<T> {
    return this.http.post<T>(this.buildUrl(url), payload, options)
      .pipe(tap(() => this.cacheService.clearCache()));
  }

  put<T>(url: string, payload?: object): Observable<T> {
    return this.http.put<T>(this.buildUrl(url), payload)
      .pipe(tap(() => this.cacheService.clearCache()));
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.buildUrl(url))
      .pipe(tap(() => this.cacheService.clearCache()));
  }

  sendBeacon(url: string, payload?: object): boolean {
    const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
    return navigator.sendBeacon(this.buildUrl(url), blob);
  }
}
