import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { IconService } from '@core/services';

@Component({
  selector: 'app-svg-icon[icon]',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnInit {
  @Input() icon: string;

  constructor(
    private iconService: IconService
  ) { }

  ngOnInit(): void {
    this.iconService.registerIcon(this.icon);
  }
}
