import { Directive, HostListener, Input } from '@angular/core';

import { AnalyticsService } from '@core/services';
import { TraceName } from '@core/types';

@Directive({
  selector: '[appAnalytics]'
})
export class AnalyticsDirective {
  @Input('appAnalytics') appAnalyticsName: TraceName;
  @Input() appAnalyticsKey: string;

  @HostListener('click') addTrace() {
    this.analyticsService.addTrace(this.appAnalyticsName, this.appAnalyticsKey);
  }

  constructor(
    private analyticsService: AnalyticsService
  ) { }
}
