import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Photo } from '@core/models';
import { PhotoSize } from '@core/types';

@Injectable({
  providedIn: 'root'
})
export class AdminPhotoService {
  constructor(
    private apiService: ApiService
  ) { }

  getPhoto(photoId: string): Observable<Photo> {
    return this.apiService.getInstance<Photo>(`/admin/photos/${photoId}`, Photo);
  }

  getPhotoFileUrl(photoId: string, photoSize: PhotoSize): string {
    return this.apiService.buildUrl(`/admin/photos/${photoId}/file/${photoSize}`);
  }

  createPhoto(photo: Photo, file: File): Observable<Photo> {
    const formData = new FormData();
    formData.append('photoJson', JSON.stringify(photo));
    formData.append('file', file);
    return this.apiService.post<Photo>('/admin/photos', formData)
      .pipe(map(photoDto => Photo.fromDto(photoDto)));
  }

  updatePhoto(photo: Photo): Observable<Photo> {
    return this.apiService.put<Photo>(`/admin/photos/${photo.id}`, photo)
      .pipe(map(photoDto => Photo.fromDto(photoDto)));
  }

  updateTourPosition(photoId: string, newTourPosition: number, photoIds: string[]): Observable<void> {
    return this.apiService.put<void>(`/admin/photos/${photoId}/tour-position/${newTourPosition}`, photoIds);
  }

  deletePhoto(photoId: string): Observable<void> {
    return this.apiService.delete<void>(`/admin/photos/${photoId}`);
  }
}
