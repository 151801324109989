import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { CurrentPosition, Tour } from '@core/models';

@Component({
  selector: 'app-tour-gallery[tours]',
  templateUrl: './tour-gallery.component.html',
  styleUrls: ['./tour-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourGalleryComponent implements OnChanges {
  @Input() tours: Tour[];
  @Input() currentPosition: CurrentPosition;
  @Input() count = 3;

  galleryTours: Tour[] = [];

  constructor() { }

  ngOnChanges(): void {
    if (this.tours) {
      this.galleryTours = this.getGalleryTours(this.tours, this.currentPosition, this.count);
    }
  }

  trackById(index: number, tour: Tour): string {
    return tour.id;
  }

  private getGalleryTours(tours: Tour[], currentPosition: CurrentPosition, count: number): Tour[] {
    if (!currentPosition) {
      return tours.slice(0, count);
    }

    return tours
      .map(tour => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(tour.toLatLng(), currentPosition.toLatLng());
        return { tour, distance };
      })
      .sort((a, b) => a.distance - b.distance)
      .map(o => o.tour)
      .slice(0, count);
  }
}
