export class DecodedAccessToken {
  email: string;

  exp: number; // Expiration Time

  iat: number; // Issued At
  // identifies the time at which the JWT was
  // issued.  This claim can be used to determine the age of the JWT.  Its
  // value MUST be a number containing a NumericDate value.  Use of this
  // claim is OPTIONAL.

  jti: string; // JWT ID
  // provides a unique identifier for the JWT.
  // The identifier value MUST be assigned in a manner that ensures that
  // there is a negligible probability that the same value will be
  // accidentally assigned to a different data object

  nbf: number; // Not Before
  // identifies the time before which the JWT
  // MUST NOT be accepted for processing.  The processing of the "nbf"
  // claim requires that the current date/time MUST be after or equal to
  // the not-before date/time listed in the "nbf" claim.  Implementers MAY
  // provide for some small leeway, usually no more than a few minutes, to
  // account for clock skew.  Its value MUST be a number containing a
  // NumericDate value.  Use of this claim is OPTIONAL.

  role: string | string[];

  tourClaim: string | string[];

  sub: string; // Subject
  // claim identifies the principal that is the
  // subject of the JWT.  The claims in a JWT are normally statements
  // about the subject.  The subject value MUST either be scoped to be
  // locally unique in the context of the issuer or be globally unique.
}
