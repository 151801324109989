import { Injectable } from '@angular/core';

import { Photo } from '@core/models';
import { PointOfView, WidthHeight, Embedded } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StreetViewGeometryService {
  // photo zoom equals to street view zoom, when width of not cropped photo equals to width of street view container
  private readonly PHOTO_TO_STREET_VIEW_RATIO = 0.8;

  readonly EPS = 1e-6;

  constructor() { }

  getStreetViewPointOfView(photo: Photo, streetViewAspect: number): PointOfView {
    const streetViewPov = this.getStreetViewPov(photo);
    const streetViewZoom = this.getStreetViewZoom(photo, streetViewAspect);
    const streetViewZoomWithinBounds = this.getStreetViewZoomWithinBounds(streetViewZoom, streetViewAspect);
    return {
      heading: streetViewPov.heading,
      pitch: streetViewPov.pitch,
      zoom: streetViewZoomWithinBounds
    };
  }

  getOverlayPointOfView(photo: Photo): PointOfView {
    const overlayPointOfView = this.getStreetViewPov(photo);
    const overlayZoom = photo.zoom - Math.log2(1 - photo.offsetLeft - photo.offsetRight);
    return {
      heading: overlayPointOfView.heading,
      pitch: overlayPointOfView.pitch,
      zoom: overlayZoom
    };
  }

  getEmbeddedPov(embedded: Embedded, streetViewPointOfView: PointOfView): google.maps.StreetViewPov {
    const streetViewPov = { heading: streetViewPointOfView.heading, pitch: streetViewPointOfView.pitch };
    const streetViewZoom = streetViewPointOfView.zoom;
    const embeddedZoom = this.getEmbeddedZoom(embedded, streetViewZoom);

    const dx = -0.5 * (embedded.offsetLeft - embedded.offsetRight);
    const dy = 0.5 / embedded.aspect * (embedded.offsetTop - embedded.offsetBottom);

    return this.getPointPov(streetViewPov, dx, dy, embeddedZoom);
  }

  getEmbeddedZoom(embedded: Embedded, streetViewZoom: number): number {
    return streetViewZoom - Math.log2(embedded.widthRatio);
  }

  getEmbeddedRatios(photo: Photo, streetViewAspect: number): WidthHeight {
    if (photo.zoom === null) {
      return {
        width: this.PHOTO_TO_STREET_VIEW_RATIO * Math.min(1, photo.aspect / streetViewAspect),
        height: this.PHOTO_TO_STREET_VIEW_RATIO * Math.min(1, streetViewAspect / photo.aspect)
      };
    } else {
      const minStreetViewMinZoom = this.getMinStreetViewZoom(streetViewAspect);
      const minEmbeddedWidthRatio = Math.pow(2, minStreetViewMinZoom - photo.zoom);

      const maxStreetViewZoom = this.getMaxStreetViewZoom(streetViewAspect);
      const maxEmbeddedWidthRatio = Math.pow(2, maxStreetViewZoom - photo.zoom);

      const embeddedCroppedAspect = photo.aspect * (1 - photo.offsetLeft - photo.offsetRight) / (1 - photo.offsetTop - photo.offsetBottom);
      const embeddedCroppedWidthRatio = this.PHOTO_TO_STREET_VIEW_RATIO * Math.min(1, embeddedCroppedAspect / streetViewAspect);

      let embeddedWidthRatio = embeddedCroppedWidthRatio / (1 - photo.offsetLeft - photo.offsetRight);
      embeddedWidthRatio = Math.min(Math.max(embeddedWidthRatio, minEmbeddedWidthRatio), maxEmbeddedWidthRatio);

      const embeddedHeightRatio = embeddedWidthRatio * streetViewAspect / photo.aspect;

      return {
        width: embeddedWidthRatio,
        height: embeddedHeightRatio
      };
    }
  }

  getEmbeddedCroppedRatios(embedded: Embedded): WidthHeight {
    return {
      width: embedded.widthRatio * (1 - embedded.offsetLeft - embedded.offsetRight),
      height: embedded.heightRatio * (1 - embedded.offsetTop - embedded.offsetBottom)
    };
  }

  checkIsPointOfViewsEqual(pointOfView1: PointOfView, pointOfView2: PointOfView): boolean {
    const isHeadingEqual = Math.abs(pointOfView1.heading - pointOfView2.heading) < this.EPS;
    const isPitchEqual = Math.abs(pointOfView1.pitch - pointOfView2.pitch) < this.EPS;
    const isZoomEqual = Math.abs(pointOfView1.zoom - pointOfView2.zoom) < this.EPS;
    return isHeadingEqual && isPitchEqual && isZoomEqual;
  }

  // returns terminal point calculated from initial point and direction vector (dx, dy);
  // positive x-axis points right, positive y-axis points up,
  // bottom left corner is (0, 0) and top right corner is (1, height / width);
  // thus dx and dy are relative (divided by width of container)
  private getPointPov(pov: google.maps.StreetViewPov, dx: number, dy: number, zoom: number): google.maps.StreetViewPov {
    const f = Math.pow(2, zoom - 2);

    const h0 = this.degToRad(pov.heading);
    const p0 = this.degToRad(pov.pitch);

    const h = h0 + Math.atan(dx / f);
    const p = p0 + Math.atan(dy / Math.sqrt(f * f + dx * dx));

    let heading = this.radToDeg(h);
    if (heading < 0) {
      heading += 360;
    } else if (heading >= 360) {
      heading -= 360;
    }

    let pitch = this.radToDeg(p);
    if (pitch < -90) {
      pitch = -180 - pitch;
    } else if (pitch > 90) {
      pitch = 180 - pitch;
    }

    return { heading, pitch };
  }

  private getStreetViewPov(photo: Photo): google.maps.StreetViewPov {
    const photoPov = { heading: photo.heading, pitch: photo.pitch };
    const photoZoom = photo.zoom;

    const dx = 0.5 * (photo.offsetLeft - photo.offsetRight);
    const dy = -0.5 / photo.aspect * (photo.offsetTop - photo.offsetBottom);

    return this.getPointPov(photoPov, dx, dy, photoZoom);
  }

  private getStreetViewZoom(photo: Photo, streetViewAspect: number): number {
    const croppedPhotoAspect = photo.aspect * (1 - photo.offsetLeft - photo.offsetRight) / (1 - photo.offsetTop - photo.offsetBottom);
    const croppedPhotoWidthRatio = this.PHOTO_TO_STREET_VIEW_RATIO * Math.min(1, croppedPhotoAspect / streetViewAspect);
    const photoWidthRatio = croppedPhotoWidthRatio / (1 - photo.offsetLeft - photo.offsetRight);
    return photo.zoom + Math.log2(photoWidthRatio);
  }

  // according to docs 0 <= zoom <= 4, but actual bounds are 0 <= minZoom <= zoom <= maxZoom <= 4,
  // where minZoom and maxZoom depend on container aspect ratio
  // ==========
  // if zoom is set via setZoom and zoom < minZoom, then getZoom returns zoom, although actual zoom is minZoom;
  // for example, if aspect ratio is 0.5 and setZoom(1) is called, then getZoom returns 1, although actual zoom is minZoom = 2;
  // same logic applies for maxZoom; thus getZoom may return different values for same actual zoom
  // ==========
  // hfov / 2 = PI * 2 ^ (-zoom - 1), where PI / 16 <= hfov <= PI (as in docs),
  // thus 0 <= zoom <= 4
  // ==========
  // tan(vfov / 2) = 1 / aspect * 2 ^ (-zoom + 1), where PI / 12 <= vfov <= PI / 2 (found experimentally),
  // thus 1 - log2(aspect) <= zoom <= 1 - log2(aspect) - log2(tan(pi / 24))
  private getStreetViewZoomWithinBounds(streetViewZoom: number, streetViewAspect: number): number {
    const minZoom = this.getMinStreetViewZoom(streetViewAspect);
    const maxZoom = this.getMaxStreetViewZoom(streetViewAspect);
    return Math.min(Math.max(streetViewZoom, minZoom), maxZoom);
  }

  private getMinStreetViewZoom(streetViewAspect: number): number {
    return Math.max(0, 1 - Math.log2(streetViewAspect));
  }

  private getMaxStreetViewZoom(streetViewAspect: number): number {
    return Math.min(4, 1 - Math.log2(streetViewAspect) - Math.log2(Math.tan(Math.PI / 24)));
  }

  private degToRad(deg: number): number {
    return deg * Math.PI / 180;
  }

  private radToDeg(rad: number): number {
    return rad * 180 / Math.PI;
  }
}
