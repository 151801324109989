import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { MapDialogData } from '@core/interfaces';
import { MapDialogComponent } from '@app/shared/components/map-dialog/map-dialog.component';

interface MapDialogOptions {
  isFullscreen?: boolean;
  isDesktop?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MapDialogService {
  private readonly mapDialogConfig: MatDialogConfig = {
    autoFocus: false,
    restoreFocus: false,
    minWidth: '100vw',
    height: '40vh',
    position: { bottom: '0px' },
    panelClass: 'map-dialog-container'
  };

  private readonly fullscreenExtraConfig: MatDialogConfig = {
    height: '100vh'
  };

  private readonly desktopExtraConfig: MatDialogConfig = {
    hasBackdrop: false,
    minWidth: '50vw'
  };

  private mapDialogRef: MatDialogRef<any>;
  private afterClosedSubscription: Subscription;

  constructor(
    private zone: NgZone,
    private dialog: MatDialog
  ) { }

  get isMapOpen(): boolean {
    return !!this.mapDialogRef;
  }

  openMap(data: MapDialogData, options: MapDialogOptions = { isFullscreen: false, isDesktop: false }): void {
    const config = { ...this.mapDialogConfig };
    if (options.isFullscreen) {
      Object.assign(config, this.fullscreenExtraConfig);
    } else if (options.isDesktop) {
      Object.assign(config, this.desktopExtraConfig);
    }

    // fix map not init after permission dialog
    this.zone.run(
      () => this.mapDialogRef = this.dialog.open<MapDialogComponent, MapDialogData>(MapDialogComponent, { ...config, data })
    );

    this.afterClosedSubscription?.unsubscribe();
    this.afterClosedSubscription = this.mapDialogRef.afterClosed()
      .subscribe(() => this.mapDialogRef = null);
  }

  closeMap(): void {
    this.mapDialogRef?.close();
  }
}

