import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { StreetViewGeometryService } from './street-view-geometry.service';
import { StreetViewService } from './street-view.service';
import { Photo } from '@core/models';
import { PointOfView, WidthHeight, Embedded } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class EmbeddedService {
  private embedded: Embedded;
  private _embeddedCroppedRatios$ = new BehaviorSubject<WidthHeight>({ width: 0, height: 0 });

  embeddedCroppedRatios$ = this._embeddedCroppedRatios$.asObservable();

  constructor(
    private streetViewService: StreetViewService,
    private streetViewGeometryService: StreetViewGeometryService
  ) { }

  updateEmbedded(photo: Photo): void {
    const streetViewAspect = this.streetViewService.getContainerAspect();
    const embeddedRatios = this.streetViewGeometryService.getEmbeddedRatios(photo, streetViewAspect);

    this.embedded = {
      aspect: photo.aspect,
      offsetTop: photo.offsetTop,
      offsetLeft: photo.offsetLeft,
      offsetBottom: photo.offsetBottom,
      offsetRight: photo.offsetRight,
      widthRatio: embeddedRatios.width,
      heightRatio: embeddedRatios.height
    };

    const embeddedCroppedRatios = this.streetViewGeometryService.getEmbeddedCroppedRatios(this.embedded);
    this._embeddedCroppedRatios$.next(embeddedCroppedRatios);
  }

  getPhotoPointOfView(): PointOfView {
    const photoPov = this.getPhotoPov();
    const photoZoom = this.getPhotoZoom();
    return {
      heading: photoPov.heading,
      pitch: photoPov.pitch,
      zoom: photoZoom
    };
  }

  getPhotoPov(): google.maps.StreetViewPov {
    const streetViewPointOfView = this.streetViewService.getPointOfView();
    return this.streetViewGeometryService.getEmbeddedPov(this.embedded, streetViewPointOfView);
  }

  getPhotoZoom(): number {
    const streetViewZoom = this.streetViewService.getPointOfView().zoom;
    return this.streetViewGeometryService.getEmbeddedZoom(this.embedded, streetViewZoom);
  }

  setStreetViewPointOfView(photo: Photo): void {
    const streetViewPointOfView = this.streetViewGeometryService.getStreetViewPointOfView(photo, this.streetViewService.getContainerAspect());

    // setZoom comes before setPov, because embedded pov calculations depends on street view zoom
    const streetViewZoom = streetViewPointOfView.zoom;
    this.streetViewService.streetView.setZoom(streetViewZoom);

    const streetViewPov = { heading: streetViewPointOfView.heading, pitch: streetViewPointOfView.pitch };
    this.streetViewService.streetView.setPov(streetViewPov);
  }
}
