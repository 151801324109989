<form [formGroup]="form" class="form" (ngSubmit)="submitForm($event)">
  <mat-form-field floatLabel="always" hideRequiredMarker *ngFor="let control of config.controls; trackBy: trackByIndex">
    <mat-label>{{ control.label }}</mat-label>
    <input [type]="control.type" [formControlName]="control.name" matInput required>
  </mat-form-field>

  <button type="submit" mat-raised-button color="white-on-blue" *appLet="isLoading$ | async as isLoading"
    [disabled]="form.invalid || isLoading">
    {{ config.buttonText }}
    <mat-icon *ngIf="isLoading" class="loading-spinner">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </button>

  <div *ngIf="errorMessage$ | async as errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
</form>
