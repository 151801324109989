import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoWindowService {
  private infoWindows = new Set<google.maps.InfoWindow>();

  constructor(
    private ngZone: NgZone
  ) { }

  addInfoWindow(content: Node): google.maps.InfoWindow {
    let infoWindow: google.maps.InfoWindow;
    this.ngZone.runOutsideAngular(() => infoWindow = new google.maps.InfoWindow({ content }));

    this.infoWindows.add(infoWindow);
    return infoWindow;
  }

  removeAllInfoWindows(): void {
    this.infoWindows.forEach(infoWindow => this.removeInfoWindow(infoWindow));
  }

  private removeInfoWindow(infoWindow: google.maps.InfoWindow): void {
    if (infoWindow) {
      google.maps.event.clearInstanceListeners(infoWindow);
      infoWindow.close();

      this.infoWindows.delete(infoWindow);
      infoWindow = null;
    }
  }
}
