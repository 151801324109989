import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Tour, Photo, Caption } from '@core/models';
import { KeyOfType } from '@core/types';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  constructor(
    private apiService: ApiService,
    private cacheService: CacheService
  ) { }

  getTours(): Observable<Tour[]> {
    if (this.cacheService.isToursCached) {
      return of(this.cacheService.getTours());
    } else {
      return this.apiService.getInstanceArray(`/tours`, Tour)
        .pipe(
          tap(tours => this.cacheService.cacheTours(tours))
        );
    }
  }

  getTour(tourId: string): Observable<Tour> {
    if (this.cacheService.isToursCached) {
      return of(this.cacheService.getTour(tourId));
    } else {
      return this.apiService.getInstance(`/tours/${tourId}`, Tour);
    }
  }

  getPhotos(tourId: string): Observable<Photo[]> {
    if (this.cacheService.isPhotosCached) {
      return of(this.cacheService.getPhotosByTourId(tourId));
    } else {
      return this.apiService.getInstanceArray(`/tours/${tourId}/photos`, Photo);
    }
  }

  getCaptionTranslation(tourId: string, captionType: KeyOfType<Tour, Caption>, language: string): Observable<string> {
    return this.apiService.getString(`/tours/${tourId}/${captionType}/${language}`);
  }
}
