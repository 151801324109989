import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private readonly YMDFormatter = new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: 'numeric' });
  private readonly YMFormatter = new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short' });
  private readonly MDFormatter = new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' });

  constructor() { }

  getLocalDate(utcDate: Date | string): Date {
    const _utcDate = new Date(utcDate);
    return new Date(_utcDate.getUTCFullYear(), _utcDate.getUTCMonth(), _utcDate.getUTCDate());
  }

  getUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  getDateRangeText(utcDateFrom: Date | string, utcDateTo: Date | string): string {
    if (!utcDateFrom || !utcDateTo) {
      return '';
    }

    const dateFrom = this.getLocalDate(utcDateFrom);
    const dateTo = this.getLocalDate(utcDateTo);

    const isEntireYearsOnly = this.checkIsFirstDayOfYear(dateFrom) && this.checkIsLastDayOfYear(dateTo);
    const isEntireMonthsOnly = this.checkIsFirstDayOfMonth(dateFrom) && this.checkIsLastDayOfMonth(dateTo);

    const isSameYear = dateFrom.getFullYear() === dateTo.getFullYear();
    const isSameMonth = isSameYear && dateFrom.getMonth() === dateTo.getMonth();
    const isSameDay = isSameMonth && dateFrom.getDate() === dateTo.getDate();

    const dateFromYMDParts = this.YMDFormatter.formatToParts(dateFrom);
    const dateFromMonthPart = dateFromYMDParts.find(part => part.type === 'month');
    const dateFromDayPart = dateFromYMDParts.find(part => part.type === 'day');
    const dateToYMDParts = this.YMDFormatter.formatToParts(dateTo);
    const dateToMonthPart = dateToYMDParts.find(part => part.type === 'month');
    const dateToDayPart = dateToYMDParts.find(part => part.type === 'day');

    if (isSameYear) {
      if (isSameMonth) {
        if (isSameDay) {
          return this.YMDFormatter.format(dateFrom);
        } else if (isEntireMonthsOnly) {
          return this.YMFormatter.format(dateFrom);
        } else {
          const dateParts = this.YMDFormatter.formatToParts(dateFrom);
          const dateDayPart = dateParts.find(part => part.type === 'day');
          dateDayPart.value = `${dateFromDayPart.value} - ${dateToDayPart.value}`;
          return dateParts.map(datePart => datePart.value).join('');
        }
      } else if (isEntireYearsOnly) {
        return `${dateFrom.getFullYear()}`;
      } else if (isEntireMonthsOnly) {
        const dateParts = this.YMFormatter.formatToParts(dateFrom);
        const dateMonthPart = dateParts.find(part => part.type === 'month');
        dateMonthPart.value = `${dateFromMonthPart.value} - ${dateToMonthPart.value}`;
        return dateParts.map(datePart => datePart.value).join('');
      } else {
        return `${this.MDFormatter.format(dateFrom)} - ${this.YMDFormatter.format(dateTo)}`;
      }
    } else if (isEntireYearsOnly) {
      return `${dateFrom.getFullYear()} - ${dateTo.getFullYear()}`;
    } else if (isEntireMonthsOnly) {
      return `${this.YMFormatter.format(dateFrom)} - ${this.YMFormatter.format(dateTo)}`;
    } else {
      return `${this.YMDFormatter.format(dateFrom)} - ${this.YMDFormatter.format(dateTo)}`;
    }
  }

  private checkIsFirstDayOfYear(date: Date): boolean {
    return date.getMonth() === 0 && date.getDate() === 1;
  }

  private checkIsLastDayOfYear(date: Date): boolean {
    return date.getMonth() === 11 && date.getDate() === 31;
  }

  private checkIsFirstDayOfMonth(date: Date): boolean {
    return date.getDate() === 1;
  }

  private checkIsLastDayOfMonth(date: Date): boolean {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return date.getDate() === lastDayOfMonth;
  }
}
