import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, zip } from 'rxjs';
import { filter, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { PhotoService, GeolocationService, MapService, MarkerService, MarkerClustererService, AnalyticsService } from '@core/services';
import { Photo, CurrentPosition, Tour } from '@core/models';
import { MapDialogData } from '@core/interfaces';

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();
  private photoMarkersByTourId = new Map<string, google.maps.Marker[]>();
  private currentPhotoMarker: google.maps.Marker;
  private currentPositionMarker: google.maps.Marker;

  currentPosition$: Observable<CurrentPosition>;
  sortedTours$: Observable<Tour[]>;
  isMenuOpen = false;
  tourColors: { [tourId: string]: string } = {};

  constructor(
    private router: Router,
    private photoService: PhotoService,
    private geolocationService: GeolocationService,
    private mapService: MapService,
    private markerService: MarkerService,
    private markerClustererService: MarkerClustererService,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: MapDialogData
  ) { }

  ngOnInit(): void {
    const photos$ = this.photoService.getPhotos()
      .pipe(
        tap(photos => {
          this.pickTourColors(photos);
          this.addPhotoMarkers(photos);
          this.addPhotoMarkerClusters();
        })
      );

    this.currentPosition$ = this.geolocationService.currentPosition$
      .pipe(
        tap(currentPosition => this.moveCurrentPositionMarker(currentPosition)),
        shareReplay(1)
      );

    this.sortedTours$ = this.currentPosition$
      .pipe(
        map(currentPosition => this.getSortedTours(currentPosition))
      );

    zip(photos$, this.currentPosition$)
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(([photos, currentPosition]) => {
        setTimeout(() => {
          this.fitMapToMarkers(photos, currentPosition);
          this.mapService.fromMapEventOnce('idle')
            .subscribe(() => this.mapService.fixGreyAreaAfterResize());
        });
      });
  }

  ngOnDestroy(): void {
    this.markerClustererService.removeClusterer();
    this.markerService.removeAllMarkers();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  findMe(): void {
    this.geolocationService.findMe()
      .pipe(
        filter(currentPosition => currentPosition.source !== 'default')
      )
      .subscribe(currentPosition => this.mapService.map.panTo(currentPosition.toLatLng()));
  }

  private pickTourColors(photos: Photo[]): void {
    let tourIds: string[];
    if (this.data.tours) {
      tourIds = this.data.tours.map(tour => tour.id);
    } else {
      tourIds = photos.filter(photo => photo.tourPosition === 0).map(photo => photo.tourId);
    }
    tourIds.forEach((tourId, index) => this.tourColors[tourId] = this.markerService.getMarkerColor(index));
  }

  private addPhotoMarkers(photos: Photo[]): void {
    const photoMarkerMap = new Map<string, google.maps.Marker[]>();

    photos.forEach(photo => {
      if (!this.photoMarkersByTourId.has(photo.tourId)) {
        this.photoMarkersByTourId.set(photo.tourId, []);
      }

      const photoLatLng = photo.toLatLng();
      const photoMarkersOfSameTour = this.photoMarkersByTourId.get(photo.tourId);
      const isAnyPhotoMarkerOfSameTourNearby = this.checkIsAnyMarkerNearby(photoLatLng, photoMarkersOfSameTour);

      if (!isAnyPhotoMarkerOfSameTourNearby) {
        const tourColor = this.tourColors[photo.tourId];
        const photoMarker = this.markerService.addPhotoMarker(photoLatLng, tourColor);
        photoMarkersOfSameTour.push(photoMarker);

        const photoMarkerLatLngString = photoMarker.getPosition().toString();
        if (photoMarkerMap.has(photoMarkerLatLngString)) {
          photoMarkerMap.get(photoMarkerLatLngString).push(photoMarker);
        } else {
          photoMarkerMap.set(photoMarkerLatLngString, [photoMarker]);
        }

        this.markerService.fromMarkerEvent(photoMarker, 'click')
          .subscribe(() => this.goToPhoto(photo));
      }

      if (this.data.photoId === photo.id) {
        this.currentPhotoMarker = this.getNearestMarker(photoLatLng, photoMarkersOfSameTour);
        this.currentPhotoMarker.setAnimation(google.maps.Animation.BOUNCE);
      }
    });

    photoMarkerMap.forEach(markers => {
      if (markers.length >= 2) {
        this.spreadMarkersAtSameLatLng(markers);
      }
    });
  }

  private addPhotoMarkerClusters(): void {
    let photoMarkers = this.getAllPhotoMarkers();
    if (this.currentPhotoMarker) {
      photoMarkers = photoMarkers.filter(photoMarker => photoMarker !== this.currentPhotoMarker);
    }

    this.markerClustererService.addClusterer(photoMarkers);
  }

  private moveCurrentPositionMarker(currentPosition: CurrentPosition): void {
    if (currentPosition.source === 'navigator') {
      const currentPositionLatLng = currentPosition.toLatLng();
      if (this.currentPositionMarker) {
        this.markerService.moveMarker(this.currentPositionMarker, currentPositionLatLng);
      } else {
        this.currentPositionMarker = this.markerService.addCurrentPositionMarker(currentPositionLatLng);
      }
    }
  }

  private getSortedTours(currentPosition: CurrentPosition): Tour[] {
    if (!this.data.tours) {
      return [];
    }

    return this.data.tours
      .map(tour => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(tour.toLatLng(), currentPosition.toLatLng());
        return { tour, distance };
      })
      .sort((a, b) => a.distance - b.distance)
      .map(o => o.tour);
  }

  private fitMapToMarkers(photos: Photo[], currentPosition: CurrentPosition): void {
    let bounds: google.maps.LatLngBounds;

    if (this.currentPhotoMarker) {
      bounds = this.getBoundsByCurrentPhotoMarker(photos);
    } else if (this.currentPositionMarker) {
      bounds = this.getBoundsByCurrentPositionMarker();
    } else {
      bounds = this.getBoundsByNearestMarker(currentPosition);
    }

    this.mapService.fromMapEventOnce('bounds_changed')
      .subscribe(() => {
        if (this.mapService.map.getZoom() > 18) {
          this.mapService.map.setZoom(18);
        }
      });

    this.mapService.map.fitBounds(bounds, 50);
  }

  private getBoundsByCurrentPhotoMarker(photos: Photo[]): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    const currentPhotoLatLng = this.currentPhotoMarker.getPosition();
    const currentPhoto = photos.find(photo => photo.id === this.data.photoId);
    const photoMarkersOfSameTour = this.photoMarkersByTourId.get(currentPhoto.tourId);
    photoMarkersOfSameTour
      .filter(marker => this.computeDistance(currentPhotoLatLng, marker.getPosition()) <= 100)
      .forEach(marker => bounds.extend(marker.getPosition()));

    return bounds;
  }

  private getBoundsByCurrentPositionMarker(): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    const currentPositionLatLng = this.currentPositionMarker.getPosition();
    bounds.extend(currentPositionLatLng);

    const allPhotoMarkers = this.getAllPhotoMarkers();
    const nearestMarker = this.getNearestMarker(currentPositionLatLng, allPhotoMarkers, { minDistance: 100, maxDistance: 10000 });
    if (nearestMarker) {
      bounds.extend(nearestMarker.getPosition());
    }

    return bounds;
  }

  private getBoundsByNearestMarker(currentPosition: CurrentPosition): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    const currentPositionLatLng = currentPosition.toLatLng();
    const allPhotoMarkers = this.getAllPhotoMarkers();
    if (allPhotoMarkers.length) {
      const nearestMarker = this.getNearestMarker(currentPositionLatLng, allPhotoMarkers, { minDistance: 0, maxDistance: 10000 })
        ?? this.getNearestMarker(currentPositionLatLng, allPhotoMarkers);
      const nearestMarkerLatLng = nearestMarker.getPosition();
      bounds.extend(nearestMarkerLatLng);

      const nearestMarker2 = this.getNearestMarker(nearestMarkerLatLng, allPhotoMarkers, { minDistance: 100, maxDistance: 1000 });
      if (nearestMarker2) {
        bounds.extend(nearestMarker2.getPosition());
      }
    } else {
      bounds.extend(currentPositionLatLng);
    }

    return bounds;
  }

  private getAllPhotoMarkers(): google.maps.Marker[] {
    return [...this.photoMarkersByTourId.values()].flat();
  }

  private spreadMarkersAtSameLatLng(markers: google.maps.Marker[]): void {
    markers.forEach((marker, index) => {
      const heading = 180 * (2 * index + 1) / markers.length;
      const newLatLng = google.maps.geometry.spherical.computeOffset(marker.getPosition(), 10, heading);
      marker.setPosition(newLatLng);
    });
  }

  private checkIsAnyMarkerNearby(latLng: google.maps.LatLng, markers: google.maps.Marker[]): boolean {
    return markers.some(marker => this.computeDistance(latLng, marker.getPosition()) <= 100);
  }

  private getNearestMarker(
    latLng: google.maps.LatLng, markers: google.maps.Marker[], { minDistance, maxDistance } = { minDistance: 0, maxDistance: Infinity }
  ): google.maps.Marker {
    let nearestMarker: google.maps.Marker;
    let nearestDistance: number;

    markers.forEach(marker => {
      const markerLatLng = marker.getPosition();
      const distance = this.computeDistance(latLng, markerLatLng);

      if (distance < minDistance || distance > maxDistance) {
        return;
      }

      if (!nearestMarker || distance < nearestDistance) {
        nearestMarker = marker;
        nearestDistance = distance;
      }
    });

    return nearestMarker;
  }

  private computeDistance(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): number {
    return google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);
  }

  private goToPhoto(photo: Photo): void {
    this.analyticsService.addTrace('map-photo', photo.id);
    this.router.navigateByUrl(`tours/${photo.tourId}/${photo.id}`);
  }
}
