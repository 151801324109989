import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { PHOTOS, TOURS } from '@core/mocks';

@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    switch (true) {
      case url.endsWith('/tours') && method === 'GET':
        return this.ok(TOURS);
      case url.match(/\/tours\/\d+$/) && method === 'GET':
        return this.ok(TOURS.find(tour => tour.id === url.match(/\/tours\/(\d+)$/)[1]));
      case url.match(/\/tours\/\d+\/photos$/) && method === 'GET':
        return this.ok(PHOTOS.filter(photo => photo.tourId === url.match(/\/tours\/(\d+)\/photos$/)[1]));
      case url.match(/\/tours\/\d+$/) && ['POST', 'PUT', 'DELETE'].includes(method):
        return this.ok();
      case url.match(/\/photos\/\d+$/) && method === 'GET':
        return this.notFound();
      case url.match(/\/photos\/\d+$/) && ['POST', 'PUT', 'DELETE'].includes(method):
        return this.ok();
      default:
        return next.handle(request);
    }
  }

  private ok(body?: any): Observable<HttpResponse<any>> {
    return of(new HttpResponse({ status: 200, body })).pipe(
      delay(500)
    );
  }

  private notFound(): Observable<HttpResponse<any>> {
    return of(new HttpResponse({ status: 404 })).pipe(
      delay(500)
    );
  }
}
