import { Injectable, ViewContainerRef, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly hiddenFeatureTypes = ['poi.business', 'transit.station'];

  private container: HTMLDivElement;

  map: google.maps.Map;

  constructor(
    private ngZone: NgZone,
    private eventService: EventService
  ) {
    this.ngZone.runOutsideAngular(() => this.map = this.initMap());
  }

  attachContainer(viewContainerRef: ViewContainerRef): void {
    viewContainerRef.element.nativeElement.appendChild(this.container);
  }

  addZoomControl(): void {
    this.map.setOptions({ zoomControl: true });
  }

  removeZoomControl(): void {
    this.map.setOptions({ zoomControl: false });
  }

  fromMapEvent<T>(eventName: string): Observable<T> {
    return this.eventService.fromEvent<T>(this.map, eventName);
  }

  fromMapEventOnce<T>(eventName: string): Observable<T> {
    return this.fromMapEvent<T>(eventName)
      .pipe(
        take(1)
      );
  }

  fixGreyAreaAfterResize(): void {
    const width = this.container.style.width;
    const height = this.container.style.height;
    this.container.style.width = this.container.offsetWidth - 1 + 'px';
    this.container.style.height = this.container.offsetHeight - 1 + 'px';
    setTimeout(() => {
      this.container.style.width = width;
      this.container.style.height = height;
    });
  }

  private initMap(): google.maps.Map {
    this.container = document.createElement('div');
    this.container.style.height = '100%';
    return new google.maps.Map(this.container, {
      disableDefaultUI: true,
      zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
      styles: this.getMapTypeStyles()
    });
  }

  private getMapTypeStyles(): google.maps.MapTypeStyle[] {
    return this.hiddenFeatureTypes.map(featureType => ({
      featureType,
      stylers: [{ visibility: 'off' }]
    }));
  }
}
