import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapGeometryService {
  EDITOR_INITIAL_ZOOM = 15;
  COVERAGE_LAYER_MIN_ZOOM = 16;

  constructor() { }

  getLatLngBounds(latLng: google.maps.LatLng, zoom: number, isMouseClick: boolean): google.maps.LatLngBounds {
    const radiusInMeters = this.getRadiusInMeters(latLng.lat(), zoom, isMouseClick);
    const distanceInMeters = radiusInMeters * Math.sqrt(2);
    const sw = google.maps.geometry.spherical.computeOffset(latLng, distanceInMeters, 225);
    const ne = google.maps.geometry.spherical.computeOffset(latLng, distanceInMeters, 45);
    return new google.maps.LatLngBounds(sw, ne);
  }

  getRadiusInMeters(lat: number, zoom: number, isMouseClick: boolean): number {
    const radiusInPixels = isMouseClick ? 20 : 50;
    const earthRadiusInMeters = 6378137;
    const pixelInMeters = earthRadiusInMeters * Math.PI / 128 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
    return radiusInPixels * pixelInMeters;
  }
}
