import { Injectable } from '@angular/core';

import { Photo, Tour } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private tours: Tour[] | null = null;
  private photos: Photo[] | null = null;

  constructor() { }

  get isToursCached(): boolean {
    return this.tours !== null;
  }

  get isPhotosCached(): boolean {
    return this.photos !== null;
  }

  cacheTours(tours: Tour[]): void {
    this.tours = tours;
  }

  cachePhotos(photos: Photo[]): void {
    this.photos = photos;
  }

  clearCache(): void {
    this.tours = null;
    this.photos = null;
  }

  getTours(): Tour[] {
    return this.tours;
  }

  getTour(tourId: string): Tour {
    return this.tours?.find(tour => tour.id === tourId);
  }

  getPhotos(): Photo[] {
    return this.photos;
  }

  getPhotosByTourId(tourId: string): Photo[] {
    return this.photos?.filter(photo => photo.tourId === tourId);
  }

  getPhoto(photoId: string): Photo {
    return this.photos?.find(photo => photo.id === photoId);
  }
}
