import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './api/auth.service';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private authService: AuthService,
    private jwtService: JwtService
  ) { }

  refreshJwt(): () => Observable<any> {
    return () => {
      if (this.authService.isLoggedIn) {
        return this.authService.refreshJwt()
          .pipe(
            catchError(() => of(this.jwtService.removeJwt()))
          );
      }
    };
  }
}
