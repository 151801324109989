import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private registeredIcons = new Set<string>();
  private iconsFolderPath = 'assets/icons';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  registerIcon(icon: string): void {
    if (!this.registeredIcons.has(icon)) {
      const iconFilePath = `${this.iconsFolderPath}/${icon}.svg`;
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(iconFilePath));
      this.registeredIcons.add(icon);
    }
  }
}
