import { Injectable } from '@angular/core';
import { from, fromEvent, interval, merge, Observable } from 'rxjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { ApiService } from './api.service';
import { AnalyticsRequest, Trace } from '@core/models';
import { TraceName } from '@core/types';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private readonly ANALYTICS_SENDING_INTERVAL = 5000;
  private traces: Trace[] = [];

  constructor(
    private apiService: ApiService
  ) {
    this.getFingerprint()
      .subscribe(fingerprint => this.startSendingAnalytics(fingerprint));
  }

  addTrace(name: TraceName, key?: string): void {
    const trace: Trace = { date: new Date(), name, key };
    this.traces.push(trace);
  }

  private getFingerprint(): Observable<string> {
    return from(
      FingerprintJS.load()
        .then(agent => agent.get())
        .then(result => result.visitorId)
    );
  }

  private startSendingAnalytics(fingerprint: string): void {
    merge(
      interval(this.ANALYTICS_SENDING_INTERVAL),
      fromEvent(window, 'unload'),
      fromEvent(window, 'beforeunload'),
      fromEvent(document, 'visibilitychange')
    )
      .subscribe(() => this.sendData(fingerprint));
  }

  private sendData(fingerprint: string): void {
    if (this.traces.length > 0) {
      const analyticsRequest: AnalyticsRequest = { fingerprint, traces: this.traces };
      const isSuccessful = this.apiService.sendBeacon('/analytics', analyticsRequest);
      if (isSuccessful) {
        this.traces = [];
      }
    }
  }
}
