<a [routerLink]="tour ? ['/tours', tour.id] : null" [class.disabled-link]="!tour" appAnalytics="gallery"
  [appAnalyticsKey]="tour?.id">
  <div class="thumbnail-container">
    <img src="assets/images/dummy.png" alt="dummy" class="thumbnail" [style.filter]="dummyFilter" />
    <img [src]="tour.coverPhotoId | photoSrc:'small' | async" alt="thumbnail" *ngIf="tour" class="thumbnail"
      (load)="dummyFilter='opacity(0)'" />
  </div>

  <mat-card>
    <mat-card-title>
      {{ tour ? (tour | translateTitle | async) : '' }}
    </mat-card-title>

    <mat-card-content>
      <div *ngIf="tour" class="photo-container">
        <app-svg-icon icon="card/photo" class="photo-icon"></app-svg-icon>
        <span>{{ tour.photoCount < 100 ? tour.photoCount : '...' }}</span>
      </div>
      <div *ngIf="distance" class="distance-container">
        <app-svg-icon icon="card/distance" class="distance-icon"></app-svg-icon>
        <span>{{ distance }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</a>
