import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DirectivesModule } from '@app/shared/directives/directives.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { MaterialModule } from '@app/shared/material.module';

import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { LogoComponent } from './logo/logo.component';
import { MapComponent } from './map/map.component';
import { MapAutocompleteComponent } from './map-autocomplete/map-autocomplete.component';
import { MapDialogComponent } from './map-dialog/map-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SimpleFormComponent } from './simple-form/simple-form.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { TourCardComponent } from './tour-card/tour-card.component';
import { TourGalleryComponent } from './tour-gallery/tour-gallery.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // TODO check if RouterModule can be imported like this (without forRoot and forChild)
    RouterModule,
    DirectivesModule,
    PipesModule,
    MaterialModule
  ],
  declarations: [
    AlertDialogComponent,
    LogoComponent,
    MapComponent,
    MapAutocompleteComponent,
    MapDialogComponent,
    PageNotFoundComponent,
    SimpleFormComponent,
    SvgIconComponent,
    TourCardComponent,
    TourGalleryComponent
  ],
  exports: [
    AlertDialogComponent,
    LogoComponent,
    MapComponent,
    MapAutocompleteComponent,
    MapDialogComponent,
    PageNotFoundComponent,
    SimpleFormComponent,
    SvgIconComponent,
    TourCardComponent,
    TourGalleryComponent
  ]
})
export class ComponentsModule { }
