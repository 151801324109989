import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhotoLocation } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PhotoLocationService {
  private _streetViewService: google.maps.StreetViewService;

  constructor(
    private ngZone: NgZone
  ) {
    this._streetViewService = new google.maps.StreetViewService();
    // TODO check if lat lng have been changed (getLatLngByPano with StreetViewPanoRequest)
  }

  getPhotoLocationByLatLng(latLng: google.maps.LatLng, radiusInMeters?: number): Observable<PhotoLocation> {
    const streetViewLocationRequest = this.constructStreetViewLocationRequest(latLng, radiusInMeters);
    return this.getStreetViewLocation(streetViewLocationRequest)
      .pipe(
        map((location: google.maps.StreetViewLocation) => this.mapToPhotoLocation(location))
      );
  }

  private constructStreetViewLocationRequest(latLng: google.maps.LatLng, radiusInMeters?: number): google.maps.StreetViewLocationRequest {
    const isExactLocation = radiusInMeters >= 0 && radiusInMeters <= 100;
    const preference = isExactLocation ? google.maps.StreetViewPreference.NEAREST : google.maps.StreetViewPreference.BEST;
    const radius = radiusInMeters ?? 1000;
    const source = isExactLocation ? google.maps.StreetViewSource.DEFAULT : google.maps.StreetViewSource.OUTDOOR;
    return { location: latLng, preference, radius, source };
  }

  private getStreetViewLocation(request: google.maps.StreetViewLocationRequest): Observable<google.maps.StreetViewLocation> {
    return new Observable<google.maps.StreetViewLocation>(observer => {
      this._streetViewService.getPanorama(request, (data, status) => {
        this.ngZone.run(() => {
          if (status === google.maps.StreetViewStatus.OK) {
            observer.next(data.location);
            observer.complete();
          } else {
            observer.error();
          }
        });
      });
    });
  }

  private mapToPhotoLocation(location: google.maps.StreetViewLocation): PhotoLocation {
    return {
      latitude: location.latLng.lat(),
      longitude: location.latLng.lng(),
      pano: location.pano
    };
  }
}
