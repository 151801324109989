import { Directive, Input, OnInit, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  $implicit: T | null;
  appLet: T | null;
}

@Directive({
  selector: '[appLet]'
})
export class LetDirective<T> implements OnInit, OnDestroy {
  // ngLet feature: https://github.com/angular/angular/issues/15280
  private context: LetContext<T> = { $implicit: null, appLet: null };

  @Input() set appLet(value: T) {
    this.context.$implicit = value;
    this.context.appLet = value;
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<LetContext<T>>
  ) { }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }
}
