import { Component, OnInit, ChangeDetectionStrategy, Input, ViewContainerRef } from '@angular/core';

import { MapService } from '@core/services';

@Component({
  selector: 'app-map[latitude][longitude][zoom]',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    // TODO add comment about attachContainer and ngBeforeDestroy
    // https://github.com/angular/angular/issues/17404
    this.mapService.attachContainer(this.viewContainerRef);

    const latLng = new google.maps.LatLng(this.latitude, this.longitude);
    this.mapService.map.setCenter(latLng);
    this.mapService.map.setZoom(this.zoom);
  }
}
