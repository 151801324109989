import { Role } from '@core/enums';

export class User {
  id: string;
  userName: string;
  email: string;
  registrationDate: Date;
  roles: Role[];
  tourClaims: string[];
}
