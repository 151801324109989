import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _isDesktop$ = new BehaviorSubject<boolean>(false);

  isDesktop$ = this._isDesktop$.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(Breakpoints.Web)
      .subscribe(result => this._isDesktop$.next(result.matches));
  }

  checkIsDesktop(): boolean {
    return this._isDesktop$.getValue();
  }
}
