import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SimpleFormConfig } from '@core/interfaces';

@Component({
  selector: 'app-simple-form[config]',
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFormComponent implements OnInit {
  // TODO config too sophisticated?
  @Input() config: SimpleFormConfig<any, any>;
  @Output() submitSuccess = new EventEmitter();

  form: FormGroup;
  isLoading$ = new Subject<boolean>();
  errorMessage$ = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    const controlsConfig = this.getControlsConfig();
    this.form = this.formBuilder.group(controlsConfig, { validators: this.config.validators });
  }

  submitForm(event: any): void {
    this.isLoading$.next(true);
    this.errorMessage$.next('');

    this.config.onSubmit(this.form.value)
      .pipe(
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe({
        next: () => {
          this.submitSuccess.emit();
          setTimeout(() => {
            // https://github.com/angular/components/issues/4190#issuecomment-493061839
            event.target.reset();
            this.form.reset();
          }, 1000);
        },
        error: error => this.errorMessage$.next(error.error?.message ?? error.statusText)
      });
  }

  trackByIndex(index: number): number {
    return index;
  }

  private getControlsConfig(): { [name: string]: any } {
    return Object.fromEntries(
      this.config.controls.map(({ name, type }) => [name, ['', this.getControlValidators(type)]])
    );
  }

  private getControlValidators(type: string): ValidatorFn | ValidatorFn[] {
    switch (type) {
      case 'email':
        return [Validators.required, Validators.email];
      case 'password':
        return [Validators.required];
      default:
        return [Validators.required];
    }
  }
}
