import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InspectService {
  constructor(
    private apiService: ApiService
  ) { }

  sendCommand(command: string): Observable<string> {
    return this.apiService.getString(`/inspect/${command}`);
  }
}
