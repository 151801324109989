import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Tour, Photo } from '@core/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminTourService {
  constructor(
    private apiService: ApiService
  ) { }

  getTours(): Observable<Tour[]> {
    return this.apiService.getInstanceArray<Tour>('/admin/tours', Tour);
  }

  getTour(tourId: string): Observable<Tour> {
    return this.apiService.getInstance<Tour>(`/admin/tours/${tourId}`, Tour);
  }

  getPhotos(tourId: string): Observable<Photo[]> {
    return this.apiService.getInstanceArray<Photo>(`/admin/tours/${tourId}/photos`, Photo);
  }

  getViewCount(tourId: string): Observable<number> {
    return this.apiService.getString(`/admin/tours/${tourId}/views`)
      .pipe(
        map((viewCount: string) => +viewCount)
      );
  }

  getUserNames(tourId: string): Observable<string[]> {
    return this.apiService.get<string[]>(`/admin/tours/${tourId}/users`);
  }

  createTour(tour: Tour): Observable<Tour> {
    return this.apiService.post<Tour>('/admin/tours', tour)
      .pipe(map(tourDto => Tour.fromDto(tourDto)));
  }

  addUserToTour(tourId: string, userName: string): Observable<void> {
    return this.apiService.post<void>(`/admin/tours/${tourId}/users/${userName}/add`);
  }

  removeUserFromTour(tourId: string, userName: string): Observable<void> {
    return this.apiService.post<void>(`/admin/tours/${tourId}/users/${userName}/remove`);
  }

  updateTour(tour: Tour): Observable<Tour> {
    return this.apiService.put<Tour>(`/admin/tours/${tour.id}`, tour)
      .pipe(map(tourDto => Tour.fromDto(tourDto)));
  }

  updateCoverPhotoId(tourId: string, coverPhotoId: string): Observable<void> {
    return this.apiService.put<void>(`/admin/tours/${tourId}/cover/${coverPhotoId}`);
  }

  deleteTour(tourId: string): Observable<void> {
    return this.apiService.delete<void>(`/admin/tours/${tourId}`);
  }
}
