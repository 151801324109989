import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Profile } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private apiService: ApiService
  ) { }

  getProfile(): Observable<Profile> {
    return this.apiService.get<Profile>('/profile');
  }
}
