import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  private readonly requestFields = ['place_id', 'geometry.location', 'name', 'address_component', 'formatted_address'];
  private input: HTMLInputElement;
  private autocomplete: google.maps.places.Autocomplete;

  place$ = new Subject<google.maps.places.PlaceResult>();

  constructor(
    private eventService: EventService
  ) { }

  addAutocomplete(input: HTMLInputElement): void {
    this.input = input;
    this.autocomplete = new google.maps.places.Autocomplete(input, { fields: this.requestFields });
  }

  getAutocompletePlace(): google.maps.places.PlaceResult {
    return this.autocomplete.getPlace();
  }

  clearAutocomplete(): void {
    if (this.input) {
      this.input.value = '';
    }
  }

  removeAutocomplete(): void {
    if (this.input) {
      google.maps.event.clearInstanceListeners(this.input);
      this.input = null;
    }
  }

  fromAutocompleteEvent<T>(eventName: string): Observable<T> {
    return this.eventService.fromEvent<T>(this.autocomplete, eventName);
  }
}
