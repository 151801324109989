import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

interface BottomDialogOptions {
  isDesktop?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BottomDialogService {
  private readonly bottomDialogConfig: MatDialogConfig = {
    autoFocus: false,
    restoreFocus: false,
    width: '100vw',
    maxWidth: '100vw',
    maxHeight: 'max(40vh, 200px)',
    minHeight: '70px',
    position: { bottom: '0px' },
    panelClass: 'bottom-dialog-container'
  };

  private readonly desktopExtraConfig: MatDialogConfig = {
    hasBackdrop: false,
    width: '50vw',
    maxWidth: '50vw',
    maxHeight: 'max(20vh, 200px)'
  };

  private bottomDialogRef: MatDialogRef<any>;
  private afterClosedSubscription: Subscription;

  constructor(
    private dialog: MatDialog
  ) { }

  get isDialogOpen(): boolean {
    return !!this.bottomDialogRef;
  }

  openDialog<T, D>(component: ComponentType<T>, data: D, options: BottomDialogOptions = { isDesktop: false }) {
    const config: MatDialogConfig = { ...this.bottomDialogConfig };
    if (options.isDesktop) {
      Object.assign(config, this.desktopExtraConfig);
    }

    this.bottomDialogRef = this.dialog.open<T, D>(component, { ...config, data });

    this.afterClosedSubscription?.unsubscribe();
    this.afterClosedSubscription = this.bottomDialogRef.afterClosed()
      .subscribe(() => this.bottomDialogRef = null);
  }

  closeDialog(): void {
    this.bottomDialogRef?.close();
  }

  updateDialogData<T>(data: T[keyof T]): void {
    if (this.isDialogOpen) {
      this.bottomDialogRef.componentInstance.data = data;
    }
  }
}
