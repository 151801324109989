import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private shouldApplyExifOrientation = false;

  storedFile: File = null;

  constructor() {
    this.detectIfShouldApplyExifOrientation()
      .subscribe(shouldApplyExifOrientation => this.shouldApplyExifOrientation = shouldApplyExifOrientation);
  }

  checkIsFileTypeValid(file: File, validFileTypeRe: RegExp): boolean {
    return !!file.type.match(validFileTypeRe);
  }

  checkIsFileSizeValid(file: File, maxFileSizeMb: number): boolean {
    const fileSizeMb = file.size / (1024 * 1024);
    return fileSizeMb <= maxFileSizeMb;
  }

  createCanvasFromFile(file: File): Observable<HTMLCanvasElement> {
    const objectUrl = URL.createObjectURL(file);
    return forkJoin([
      this.createImage(objectUrl)
        .pipe(
          finalize(() => URL.revokeObjectURL(objectUrl))
        ),
      this.getExifOrientation(file)
    ])
      .pipe(
        map(([image, orientation]) => this.createCanvasFromImage(image, orientation))
      );
  }

  createCanvasFromUrl(url: string): Observable<HTMLCanvasElement> {
    return this.createImage(url)
      .pipe(
        map(image => this.createCanvasFromImage(image))
      );
  }

  preloadImage(url: string): void {
    const image = new Image();
    image.src = url;
  }

  private createImage(url: string): Observable<HTMLImageElement> {
    return new Observable<HTMLImageElement>(observer => {
      const image = new Image();
      image.onload = () => {
        observer.next(image);
        observer.complete();
      };
      image.onerror = err => observer.error(err);
      image.src = url;
    });
  }

  private getExifOrientation(file: File): Observable<number> {
    if (this.shouldApplyExifOrientation) {
      return this.getArrayBuffer(file)
        .pipe(
          map(arrayBuffer => this.extractExifOrientation(arrayBuffer))
        );
    } else {
      return of(null);
    }
  }

  private getArrayBuffer(file: File): Observable<ArrayBuffer> {
    return new Observable<ArrayBuffer>(observer => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        observer.next(fileReader.result as ArrayBuffer);
        observer.complete();
      };
      fileReader.onerror = err => observer.error(err);
      fileReader.readAsArrayBuffer(file);
    });
  }

  private extractExifOrientation(arrayBuffer: ArrayBuffer): number {
    /* tslint:disable:curly no-bitwise */
    const view = new DataView(arrayBuffer);
    if (view.getUint16(0, false) !== 0xFFD8) return -2;
    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return -1;
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) return -1;
        const little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) === 0x0112) return view.getUint16(offset + (i * 12) + 8, little);
        }
      }
      else if ((marker & 0xFF00) !== 0xFF00) break;
      else offset += view.getUint16(offset, false);
    }
    return -1;
    /* tslint:enable:curly no-bitwise */
  }

  private createCanvasFromImage(image: HTMLImageElement, exifOrientation: number = 1): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (exifOrientation <= 4) {
      canvas.width = image.width;
      canvas.height = image.height;
    } else {
      canvas.width = image.height;
      canvas.height = image.width;
    }
    switch (exifOrientation) {
      case 2: context.setTransform(-1, 0, 0, 1, canvas.width, 0); break;
      case 3: context.setTransform(-1, 0, 0, -1, canvas.width, canvas.height); break;
      case 4: context.setTransform(1, 0, 0, -1, 0, canvas.height); break;
      case 5: context.setTransform(0, 1, 1, 0, 0, 0); break;
      case 6: context.setTransform(0, 1, -1, 0, canvas.width, 0); break;
      case 7: context.setTransform(0, -1, -1, 0, canvas.width, canvas.height); break;
      case 8: context.setTransform(0, -1, 1, 0, 0, canvas.height); break;
      default: break;
    }
    context.drawImage(image, 0, 0);
    return canvas;
  }

  private detectIfShouldApplyExifOrientation(): Observable<boolean> {
    // black 2x1 JPEG with EXIF orientation 6 (rotated 90° CCW)
    const testImageUrl =
      'data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA' +
      'AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA' +
      'QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE' +
      'BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x' +
      'ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA' +
      'AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q==';

    return this.createImage(testImageUrl)
      .pipe(
        map(image => image.width !== 1 || image.height !== 2)
      );
  }
}
