import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private ngZone: NgZone
  ) { }

  fromEvent<T>(instance: object, eventName: string): Observable<T> {
    return new Observable<T>(observer => {
      if (!instance) {
        observer.error();
        return;
      }

      const listener = google.maps.event.addListener(instance, eventName, (event: T) => {
        this.ngZone.run(() => observer.next(event));
      });
      return () => listener.remove();
    });
  }
}
