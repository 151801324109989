import { CurrentPositionSource } from '@core/types';
import { Photo } from './photo.model';

export class CurrentPosition {
  latitude: number;
  longitude: number;
  source: CurrentPositionSource;

  constructor(coords: { latitude: number, longitude: number }, source: CurrentPositionSource) {
    this.latitude = coords.latitude;
    this.longitude = coords.longitude;
    this.source = source;
  }

  toLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.latitude, this.longitude);
  }

  static get defaultCurrentPosition(): CurrentPosition {
    return new CurrentPosition(Photo.defaultLocation, 'default');
  }
}
