import { Injectable, ViewContainerRef, NgZone } from '@angular/core';
import { Observable, of, animationFrameScheduler, scheduled } from 'rxjs';
import { takeWhile, map, repeat, tap } from 'rxjs/operators';

import { StreetViewGeometryService } from './street-view-geometry.service';
import { EventService } from './event.service';
import { PointOfView } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StreetViewService {
  private container: HTMLDivElement;

  streetView: google.maps.StreetViewPanorama;

  constructor(
    private ngZone: NgZone,
    private streetViewGeometryService: StreetViewGeometryService,
    private eventService: EventService
  ) {
    this.ngZone.runOutsideAngular(() => this.streetView = this.initStreetView());

    // fix zoom 0 may refer to different zoom values
    this.fixZoomEqualsZero();
  }

  attachContainer(viewContainerRef: ViewContainerRef): void {
    viewContainerRef.element.nativeElement.appendChild(this.container);

    // fix black stripe may appear after reattaching
    setTimeout(() => google.maps.event.trigger(this.streetView, 'resize'));
  }

  getPointOfView(): PointOfView {
    return {
      heading: this.streetView.getPov().heading,
      pitch: this.streetView.getPov().pitch,
      zoom: this.streetView.getZoom()
    };
  }

  getContainerAspect(): number {
    return this.container.offsetWidth / this.container.offsetHeight;
  }

  setPointOfView(pointOfView: PointOfView): void {
    const isSamePointOfView = this.streetViewGeometryService.checkIsPointOfViewsEqual(this.getPointOfView(), pointOfView);
    if (!isSamePointOfView) {
      this.streetView.setPov({
        heading: pointOfView.heading,
        pitch: pointOfView.pitch
      });

      this.streetView.setZoom(pointOfView.zoom);
    }
  }

  panToPointOfView(pointOfView: PointOfView, animationTime = 500): Observable<any> {
    const isSamePointOfView = this.streetViewGeometryService.checkIsPointOfViewsEqual(this.getPointOfView(), pointOfView);
    if (isSamePointOfView) {
      return of(null);
    }

    if (animationTime <= 0) {
      return of(this.setPointOfView(pointOfView));
    }

    const fromPointOfView: PointOfView = { ...this.getPointOfView() };
    const toPointOfView: PointOfView = { ...pointOfView };

    // animate in shorter direction
    if (toPointOfView.heading - fromPointOfView.heading > 180) {
      toPointOfView.heading -= 360;
    } else if (fromPointOfView.heading - toPointOfView.heading > 180) {
      toPointOfView.heading += 360;
    }

    return scheduled(of(Date.now()), animationFrameScheduler)
      .pipe(
        map(startTime => Math.min(Date.now() - startTime, animationTime)),
        tap(diffTime => {
          this.setPointOfView({
            heading: fromPointOfView.heading + (toPointOfView.heading - fromPointOfView.heading) * diffTime / animationTime,
            pitch: fromPointOfView.pitch + (toPointOfView.pitch - fromPointOfView.pitch) * diffTime / animationTime,
            zoom: fromPointOfView.zoom + (toPointOfView.zoom - fromPointOfView.zoom) * diffTime / animationTime
          });
        }),
        repeat(),
        takeWhile(diffTime => diffTime < animationTime, true)
      );
  }

  fromStreetViewEvent<T>(name: string): Observable<T> {
    return this.eventService.fromEvent<T>(this.streetView, name);
  }

  private initStreetView(): google.maps.StreetViewPanorama {
    this.container = document.createElement('div');
    this.container.style.height = '100%';
    return new google.maps.StreetViewPanorama(this.container, {
      disableDefaultUI: true,
      motionTracking: false
    });
  }

  private fixZoomEqualsZero() {
    this.fromStreetViewEvent('zoom_changed')
      .subscribe(() => {
        if (this.streetView.getZoom() === 0) {
          setTimeout(() => this.streetView.setZoom(this.streetViewGeometryService.EPS));
        }
      });
  }
}
