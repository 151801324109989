<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>

  <input #inputEl matInput>

  <div matSuffix>
    <button mat-icon-button aria-label="Clear" *ngIf="inputEl.value" (click)="clear()">
      <app-svg-icon icon="map/close"></app-svg-icon>
    </button>
    <button mat-icon-button aria-label="Find me" appMapGeolocation>
      <app-svg-icon icon="map/find-me"></app-svg-icon>
    </button>
  </div>
</mat-form-field>
