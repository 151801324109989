import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsDirective } from './analytics.directive';
import { LetDirective } from './let.directive';
import { MapGeolocationDirective } from './map-geolocation.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AnalyticsDirective,
    LetDirective,
    MapGeolocationDirective
  ],
  exports: [
    AnalyticsDirective,
    LetDirective,
    MapGeolocationDirective
  ]
})
export class DirectivesModule { }
