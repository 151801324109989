import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MapService } from './map.service';

@Injectable({
  providedIn: 'root'
})
export class CoverageLayerService {
  private streetViewCoverageLayer: google.maps.StreetViewCoverageLayer;
  private subscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private mapService: MapService
  ) {
    this.ngZone.runOutsideAngular(() => this.streetViewCoverageLayer = new google.maps.StreetViewCoverageLayer());
  }

  enable(minZoom: number): void {
    this.subscription = this.onZoomChanged(minZoom).subscribe();
    this.attach();
  }

  disable(): void {
    this.subscription.unsubscribe();
    this.detach();
  }

  private attach(): void {
    this.streetViewCoverageLayer.setMap(this.mapService.map);
  }

  private detach(): void {
    this.streetViewCoverageLayer.setMap(null);
  }

  private onZoomChanged(minZoom: number): Observable<any> {
    return this.mapService.fromMapEvent('zoom_changed')
      .pipe(
        tap(() => {
          if (this.mapService.map.getZoom() >= minZoom) {
            this.attach();
          } else {
            this.detach();
          }
        })
      );
  }
}
