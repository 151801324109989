import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TourStatus } from '@core/enums';
import { TourAttraction } from '@core/interfaces';
import { Caption } from './caption.model';

export class Tour implements TourAttraction {
  id: string;
  title: Caption;
  description: Caption;
  coverPhotoId: string;
  status: TourStatus;
  dateCreated: Date;
  photoCount: number;
  latitude: number;
  longitude: number;
  placeId: string;
  placeName: string;
  country: string;
  town: string;
  address: string;

  constructor(attraction?: TourAttraction) {
    Object.assign(this, Tour.defaultTour, attraction);
  }

  toLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.latitude, this.longitude);
  }

  static toForm(formBuilder: FormBuilder, tour: Tour): FormGroup {
    const controls = Tour.toFormControls(tour);
    return formBuilder.group(controls);
  }

  static fromForm(form: FormGroup): Tour {
    const title = new Caption(form.value.title.toString());
    const description = new Caption(form.value.description.toString());
    return Object.assign(new Tour(), form.value, { title, description }) as Tour;
  }

  static fromDto(tour: Tour): Tour {
    const title = Caption.fromDto(tour.title);
    const description = Caption.fromDto(tour.description);
    return Object.assign(new Tour(), tour, { title, description }) as Tour;
  }

  static get defaultAttraction(): TourAttraction {
    // default attraction (Amsterdam)
    return {
      latitude: 52.3599976,
      longitude: 4.8852188,
      placeId: 'ChIJ5Ra7we4JxkcRhYVAaq5zQ9U',
      placeName: 'Rijksmuseum',
      country: 'Netherlands',
      town: 'Amsterdam',
      address: 'Museumstraat 1, 1071 XX Amsterdam, Netherlands'
    };
  }

  private static get defaultTour(): Partial<Tour> {
    return {
      id: null,
      title: new Caption(),
      description: new Caption(),
      coverPhotoId: null,
      status: TourStatus.Inactive,
      dateCreated: new Date(),
      photoCount: 0,
      ...Tour.defaultAttraction
    };
  }

  private static toFormControls(tour: Tour): { [key in keyof Partial<Tour>]: any; } {
    return {
      id: [tour.id],
      title: [tour.title.text, [Validators.pattern(/\S/)]],
      description: [tour.description.text],
      coverPhotoId: [tour.coverPhotoId],
      status: [{ value: tour.status, disabled: tour.photoCount === 0 }, [Validators.required]],
      dateCreated: [tour.dateCreated, Validators.required],
      photoCount: [tour.photoCount, [Validators.required]],
      latitude: [tour.latitude, [Validators.required, Validators.min(-90), Validators.max(90)]],
      longitude: [tour.longitude, [Validators.required, Validators.min(-180), Validators.max(180)]],
      placeId: [tour.placeId, [Validators.required]],
      placeName: [tour.placeName],
      country: [tour.country],
      town: [tour.town],
      address: [tour.placeName]
    };
  }
}
