export class Caption {
  text = '';
  language: string | null = null;
  translations: { [language: string]: string } = {};

  constructor(text?: string) {
    if (text) {
      this.text = text;
    }
  }

  static fromDto(caption: Caption): Caption {
    return Object.assign(new Caption(), caption);
  }

  get isEmpty(): boolean {
    return this.text.trim() === '';
  }

  addTranslationIfNotEmpty(text: string, language: string): void {
    if (text.trim()) {
      this.translations[language] = text;
    }
  }
}
