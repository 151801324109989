<mat-dialog-content>
  <div class="content">{{ data.content }}</div>
  <img *ngIf="data.type === 'error'" src="assets/images/error.svg" alt="error" class="error-img" />
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.hasActions">
  <button mat-stroked-button mat-dialog-close aria-label="No" color="white-on-blue-light">No</button>
  <button mat-flat-button color="white-on-blue" aria-label="Yes" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="!data.hasActions">
  <button mat-stroked-button mat-dialog-close aria-label="OK" color="white-on-blue-light"
    class="button single-button">OK</button>
</mat-dialog-actions>
