import { Injectable, NgZone } from '@angular/core';
import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';

import { MapService } from './map.service';

@Injectable({
  providedIn: 'root'
})
export class MarkerClustererService {
  private readonly spiderfyRadiusInPx = 30;
  private readonly markerClustererOptions: MarkerClustererOptions = {
    averageCenter: true,
    maxZoom: 18,
    styles: [{
      url: 'assets/icons/map/cluster.svg',
      width: 48,
      height: 56,
      anchorText: [10, 1],
      textColor: '#fff',
      textSize: 14
    }]
  };

  private markerClusterer: MarkerClusterer;

  constructor(
    private ngZone: NgZone,
    private mapService: MapService
  ) { }

  addClusterer(markers: google.maps.Marker[]): void {
    this.ngZone.runOutsideAngular(() => {
      this.markerClusterer = new MarkerClusterer(this.mapService.map, markers, this.markerClustererOptions);
    });
  }

  removeClusterer(): void {
    if (this.markerClusterer) {
      this.markerClusterer.setMap(null);
      this.markerClusterer = null;
    }
  }
}
