import { AbstractControlOptions, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { PointOfView, PhotoLocation } from '@core/interfaces';
import { offsetTopBottomValidator, offsetLeftRightValidator } from '@core/validators';
import { Caption } from './caption.model';

export class Photo implements PointOfView, PhotoLocation {
  id: string;
  title: Caption;
  description: Caption;
  dateFrom: Date;
  dateTo: Date;
  dateCreated: Date;
  dateModified: Date;
  tourId: string;
  tourPosition: number;
  aspect: number;
  offsetTop: number;
  offsetLeft: number;
  offsetBottom: number;
  offsetRight: number;
  heading: number;
  pitch: number;
  zoom: number;
  roll: number;
  latitude: number;
  longitude: number;
  pano: string;

  constructor(partialPhoto?: Pick<Photo, 'tourId' | 'aspect' | 'latitude' | 'longitude' | 'pano'>) {
    Object.assign(this, Photo.defaultPhoto, partialPhoto);
  }

  toLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.latitude, this.longitude);
  }

  static toForm(formBuilder: FormBuilder, photo: Photo): FormGroup {
    const controls = Photo.toFormControls(photo);
    const options: AbstractControlOptions = { validators: Photo.formValidators };
    return formBuilder.group(controls, options);
  }

  static fromForm(form: FormGroup): Photo {
    const title = new Caption(form.value.title.toString());
    const description = new Caption(form.value.description.toString());
    return Object.assign(new Photo(), form.value, { title, description }) as Photo;
  }

  static fromDto(photo: Photo): Photo {
    const title = Caption.fromDto(photo.title);
    const description = Caption.fromDto(photo.description);
    return Object.assign(new Photo(), photo, { title, description }) as Photo;
  }

  static get defaultLocation(): PhotoLocation {
    // default location (Amsterdam)
    return {
      latitude: 52.35894251077021,
      longitude: 4.884127142584674,
      pano: 'AWdTKpOYNdgKpv08AjO6zg'
    };
  }

  private static get defaultPhoto(): Partial<Photo> {
    return {
      id: null,
      title: new Caption(),
      description: new Caption(),
      dateFrom: null,
      dateTo: null,
      dateCreated: new Date(),
      dateModified: new Date(),
      tourId: null,
      tourPosition: -1,
      aspect: null,
      offsetTop: 0,
      offsetLeft: 0,
      offsetBottom: 0,
      offsetRight: 0,
      heading: null,
      pitch: null,
      zoom: null,
      roll: 0,
      ...Photo.defaultLocation
    };
  }

  private static toFormControls(photo: Photo): { [key in keyof Partial<Photo>]: any; } {
    return {
      id: [photo.id],
      title: [photo.title.text],
      description: [photo.description.text],
      dateFrom: [photo.dateFrom],
      dateTo: [photo.dateTo],
      dateCreated: [photo.dateCreated],
      dateModified: [photo.dateModified],
      tourId: [photo.tourId, [Validators.required]],
      tourPosition: [photo.tourPosition, [Validators.required]],
      aspect: [photo.aspect, [Validators.required]],
      offsetTop: [photo.offsetTop, [Validators.required, Validators.min(0), Validators.max(1)]],
      offsetBottom: [photo.offsetBottom, [Validators.required, Validators.min(0), Validators.max(1)]],
      offsetLeft: [photo.offsetLeft, [Validators.required, Validators.min(0), Validators.max(1)]],
      offsetRight: [photo.offsetRight, [Validators.required, Validators.min(0), Validators.max(1)]],
      heading: [photo.heading, [Validators.required, Validators.min(0), Validators.max(360)]],
      pitch: [photo.pitch, [Validators.required, Validators.min(-90), Validators.max(90)]],
      zoom: [photo.zoom, [Validators.required, Validators.min(0), Validators.max(4)]],
      roll: [photo.roll, [Validators.required, Validators.min(0), Validators.max(360)]],
      latitude: [photo.latitude, [Validators.required, Validators.min(-90), Validators.max(90)]],
      longitude: [photo.longitude, [Validators.required, Validators.min(-180), Validators.max(180)]],
      pano: [photo.pano, [Validators.required]]
    };
  }

  private static get formValidators(): ValidatorFn[] {
    return [offsetTopBottomValidator, offsetLeftRightValidator];
  }
}
