import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PhotoService } from '@core/services';
import { PhotoSize } from '@core/types';

@Pipe({
  name: 'photoSrc'
})
export class PhotoSrcPipe implements PipeTransform {
  constructor(
    private photoService: PhotoService
  ) { }

  transform(photoId: string, photoSize: PhotoSize): Observable<string> {
    return photoId
      ? this.photoService.getPhotoFileUrl(photoId, photoSize)
      : of('assets/images/dummy.png');
  }
}
