import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Photo, Tour } from '@core/models';
import { TranslateService } from '@core/services';
import { CaptionInfo } from '@core/interfaces';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) { }

  transform(tourOrPhoto: Tour | Photo): Observable<Record<'title' | 'description', CaptionInfo>> {
    return this.translateService.translate(tourOrPhoto);
  }
}
