import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Role } from '@core/enums';
import { DecodedAccessToken } from '@core/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  private readonly JWT_NAME = 'jwt';
  private jwt$ = new BehaviorSubject<string | null>(this.getJwt());

  get jwt(): string {
    return this.jwt$.value;
  }

  addJwt(jwt: string): void {
    this.jwt$.next(jwt);
    localStorage.setItem(this.JWT_NAME, jwt);
  }

  removeJwt(): void {
    this.jwt$.next(null);
    localStorage.removeItem(this.JWT_NAME);
  }

  get isJwtExisting(): boolean {
    return this.jwt !== null;
  }

  get isJwtExpired(): boolean {
    return this.getMillisecondsBeforeExpires() <= 0;
  }

  getRoles(): Observable<Role[]> {
    return this.jwt$
      .pipe(
        map(jwt => this.getDecodedAccessToken(jwt)),
        map(decodedAccessToken => decodedAccessToken ? [decodedAccessToken.role].flat().map(role => Role[role]) : [])
      );
  }

  getTourClaims(): Observable<string[]> {
    return this.jwt$
      .pipe(
        map(jwt => this.getDecodedAccessToken(jwt)),
        map(decodedAccessToken => decodedAccessToken ? [decodedAccessToken.tourClaim].flat() : [])
      );
  }

  getMillisecondsBeforeExpires(): number {
    const decodedAccessToken = this.getDecodedAccessToken(this.jwt);
    if (decodedAccessToken) {
      const expirationTimeInSeconds = decodedAccessToken.exp;
      return expirationTimeInSeconds * 1000 - Date.now();
    }
    return 0;
  }

  private getJwt(): string | null {
    return localStorage.getItem(this.JWT_NAME);
  }

  private getDecodedAccessToken(jwt): DecodedAccessToken {
    if (jwt !== null) {
      const decodedAccessTokenJson = window.atob(jwt.split('.')[1]);
      return JSON.parse(decodedAccessTokenJson);
    }
    return null;
  }
}
