import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';
import { AlertDialogData } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AlertDialogService {
  private readonly alertDialogConfig: MatDialogConfig = {
    restoreFocus: false,
    panelClass: 'alert-dialog-container'
  };

  constructor(
    private dialog: MatDialog
  ) { }

  showInfo(content: string): Observable<any> {
    const data: AlertDialogData = { content, type: 'info', hasActions: false };
    return this.showAlertDialog(data);
  }

  showError(content: string): Observable<any> {
    const data: AlertDialogData = { content, type: 'error', hasActions: false };
    return this.showAlertDialog(data);
  }

  showConfirm(content: string): Observable<any> {
    const data: AlertDialogData = { content, type: 'confirm', hasActions: true };
    return this.showAlertDialog(data);
  }

  private showAlertDialog(data: AlertDialogData): Observable<any> {
    return this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, { ...this.alertDialogConfig, data })
      .afterClosed();
  }
}
