import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function passwordsNotMatchValidator(passwordName: string, confirmPasswordName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.get(passwordName);
    const confirmPassword = control.get(confirmPasswordName);

    const errorName = 'passwordsNotMatch';
    if (password && confirmPassword && password.value !== confirmPassword.value) {
      const error: ValidationErrors = { [errorName]: true };
      confirmPassword.setErrors(error);
      return error;
    } else if (confirmPassword && confirmPassword.hasError(errorName)) {
      confirmPassword.setErrors(null);
      confirmPassword.updateValueAndValidity();
      return null;
    } else {
      return null;
    }
  };
}
