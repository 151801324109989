import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminPhotoSrcPipe } from './admin-photo-src.pipe';
import { DateRangePipe } from './date-range.pipe';
import { DistancePipe } from './distance.pipe';
import { PhotoSrcPipe } from './photo-src.pipe';
import { TranslateTitlePipe } from './translate-title.pipe';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AdminPhotoSrcPipe,
    DateRangePipe,
    DistancePipe,
    PhotoSrcPipe,
    TranslateTitlePipe,
    TranslatePipe
  ],
  exports: [
    AdminPhotoSrcPipe,
    DateRangePipe,
    DistancePipe,
    PhotoSrcPipe,
    TranslateTitlePipe,
    TranslatePipe
  ]
})
export class PipesModule { }
