import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { RegisterRequest, LoginRequest, ChangePasswordRequest, JwtResponse } from '@core/models';
import { Role } from '@core/enums';
import { ApiService } from './api.service';
import { JwtService } from '../jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private refreshJwtTimeout: number;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }

  register(registerRequest: RegisterRequest): Observable<JwtResponse> {
    return this.sendJwtRequest('/auth/register', registerRequest);
  }

  login(loginRequest: LoginRequest): Observable<JwtResponse> {
    return this.sendJwtRequest('/auth/login', loginRequest);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<JwtResponse> {
    return this.sendJwtRequest('/auth/change-password', changePasswordRequest);
  }

  refreshJwt(): Observable<JwtResponse> {
    return this.sendJwtRequest('/auth/refresh-token');
  }

  logout(): void {
    this.stopRefreshTokenTimer();
    this.jwtService.removeJwt();
    this.apiService.post<void>('/auth/logout', null, { withCredentials: true })
      .subscribe();
  }

  get isLoggedIn(): boolean {
    return this.jwtService.isJwtExisting;
  }

  get isAdministrator$(): Observable<boolean> {
    return this.checkIsInRole(Role.Administrator);
  }

  private sendJwtRequest(url: string, payload?: object): Observable<JwtResponse> {
    return this.apiService.post<JwtResponse>(url, payload, { withCredentials: true })
      .pipe(
        tap(jwtResponse => {
          this.jwtService.addJwt(jwtResponse.jwt);
          this.startRefreshTokenTimer();
        })
      );
  }

  private startRefreshTokenTimer(): void {
    const timeout = this.jwtService.getMillisecondsBeforeExpires();
    this.refreshJwtTimeout = setTimeout(() => this.refreshJwt().subscribe(), timeout);
  }

  private stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshJwtTimeout);
  }

  private checkIsInRole(role: Role): Observable<boolean> {
    return this.jwtService.getRoles()
      .pipe(
        map(roles => roles.includes(role))
      );
  }
}
