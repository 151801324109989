import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { User, ResetPasswordRequest } from '@core/models';
import { Role } from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private apiService: ApiService
  ) { }

  getUsers(): Observable<User[]> {
    return this.apiService.get<User[]>('/users');
  }

  getUser(userName: string): Observable<User> {
    return this.apiService.get<User>(`/users/${userName}`);
  }

  resetPassword(userName: string, resetPasswordRequest: ResetPasswordRequest): Observable<void> {
    return this.apiService.post<void>(`/users/${userName}/reset-password`, resetPasswordRequest);
  }

  updateRoles(userName: string, roles: Role[]): Observable<void> {
    return this.apiService.put<void>(`/users/${userName}/roles`, roles);
  }

  updateTourClaims(userName: string, tourClaims: string[]): Observable<void> {
    return this.apiService.put<void>(`/users/${userName}/claims`, tourClaims);
  }

  deleteUser(userName: string): Observable<void> {
    return this.apiService.delete<void>(`/users/${userName}`);
  }
}
